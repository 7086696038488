/* Ezair */
html.ezair-no-iframe-class {
    overflow-y: auto;
}

html.ezair-iframe-class {
    overflow: hidden;
}

.web-font h1,
.web-font h2,
.web-font h3 {
    text-transform: none;
}

/* Alternative Fonts */
.web-font body.alt-font,
.web-font .alt-font input,
.web-font .alt-font select,
.web-font .alt-font textarea {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    letter-spacing: normal;
}

.web-font .alt-font .gotham-book,
.alt-font .gotham-book,
.web-font .alt-font button.plain-text-btn {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.web-font .alt-font .gotham-bold,
.alt-font .gotham-bold,
.web-font .alt-font .font-weight-700 {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 700;
}

.web-font .alt-font .gotham-xn-book,
.alt-font .gotham-xn-book,
.web-font .alt-font button:not(.tooltip),
.web-font .alt-font .button {
    font-family: 'PT Sans Narrow', Arial, Helvetica, sans-serif;
}

.web-font .alt-font .gotham-xn-medium,
.alt-font .gotham-xn-medium {
    font-family: 'PT Sans Narrow', Arial, Helvetica, sans-serif;
    font-weight: 700;
}

.web-font .alt-font h1,
.web-font .alt-font h2,
.web-font .alt-font h3,
.web-font .alt-font h4,
.web-font .alt-font h5,
.web-font .alt-font h6 {
    font-family: 'PT Sans Narrow', Arial, Helvetica, sans-serif;
}

.alt-font .full-tab,
.alt-font .compact-tab,
.alt-font .droptab-selector {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.alt-font .full-tab a,
.alt-font .compact-tab a {
    letter-spacing: .25px;
}

.alt-font .ez-fare-select-btn .button {
    font-family: 'PT Sans Narrow', Arial, Helvetica, sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
}

.block {
    display: block;
}

.inline {
    display: inline;
}

.inline-flex {
    display: inline-flex;
}

.xs-absolute {
    position: absolute;
}

.underline {
    text-decoration: underline;
}

.no-margin {
    margin: 0;
}

.margin-paragraph {
    margin: 1em 0;
}

.margin-5 {
    margin-left: 5px;
    margin-right: 5px;
}

.margin-10 {
    margin: 10px;
}

.margin-50-left {
    margin-left: 50px;
}

.clear-both {
    clear: both;
}

.border-radius-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

#javascript-disabled-warning {
    background: #bd0000;
    z-index: 99999;
    position: relative;
    padding: 1em;
    color: white;
    text-align: center;
    border: 2px solid white;
}

.button.disabled {
    border-color: #A5A5A5;
}

/*.light-blue-bg {    
    background: #e5f3fd;
}
.fieldset-block {   
    background-color: #e5f3fd;
    border: 0;
}*/
img.rwd {
    max-width: 100%;
}

.inline-block {
    display: inline-block;
}

.float-none {
    float: none;
}

.mr-saving-img,
.mr-saving-msg {
    display: inline-block;
    vertical-align: top;
}

.mr-saving-msg {
    padding-top: 8px;
}

.lt-ie9 .float-none {
    float: none !important;
}

.ez-darkblue-bg {
    background-color: #007ba3;
}

.ez-lightestblue-bg {
    background-color: #e7eff6;
}

.ez-lighterblue-bg {
    background-color: #cedfec;
}

.ez-lightblue-bg {
    background-color: #b4cfe3;
}

.ez-white-bg {
    background-color: #ebebeb;
}

.print-btn>.ir {
    margin-top: -3px;
    vertical-align: middle;
}

.print-btn .printing {
    background: url(/images/global/ajax_loader.gif) no-repeat center center;
    background-size: contain;
}

.ez-action-item button {
    border: 0;
    background: transparent;
}

.ez-action-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.ez-action-list li {
    display: inline-block;
    border-right: 1px solid #b2b2b2;
}

.ez-action-list li:last-child {
    border-right: 0;
    padding-right: 0;
}

.ez-action-list li:last-child button {
    padding-right: 0;
}

.ccl-blue {
    color: #004a98;
}

.web-font select,
.web-font select option {
    letter-spacing: normal;
}

.align-text-top {
    vertical-align: text-top;
}

.ez-strike-through {
    position: relative;
    display: inline-block;
}

/* using a pseudo element drawn line instead of text line-through because it looks better - note the double colon to exclude ie8 (see below) */
.ez-strike-through::after {
    content: " ";
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 45%;
    background: black;
}

/* ie8 has a problem with not redrawing pseudo elements after JS changes, so use the standard line-through instead */
.lt-ie9 .ez-strike-through {
    text-decoration: line-through;
}

/* for Chrome on Android */
select,
input[type='text'],
textarea {
    background-color: white;
}

input[type="text"].error {
    background-color: #fdfde0;
}

picture.rwd {
    max-width: 100%;
}

#princess-footer {
    margin-top: 0;
}

#cp-footer {
    margin-top: 0;
    padding-bottom: 25px;
}

.ez-emergency-lightbox .mfp-container .mfp-close-wrapper {
    position: absolute;
    top: -5px;
}

.mfp-container #session-refresh .mfp-close {
    top: 7px;
    right: 7px;
    border-radius: 0%;
    background-color: none;
}

.mfp-container #name-acknowledgement .mfp-close {
    top: 7px;
    right: 7px;
    border-radius: 0%;
    background-color: none;
}

#ez-bg-loading {
    position: fixed;
    width: 100%;
    height: 100%;
}

#ez-bg-loading:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

#ez-bg-loading-bg {
    display: inline-block;
    vertical-align: middle;
    background-color: rgb(50, 91, 135);
    background-color: rgba(25, 61, 102, 0.7);
    border-radius: 8px;
    padding: 10px;
}

.lt-ie9 #ez-bg-loading-bg {
    max-width: 32px;
}

#ez-bg-loading-img {
    display: block;
    max-width: 32px;
}

.ezair .article-header {
    padding: 15px;
}

.article-header h2 {
    letter-spacing: 1px;
}

.article-header h1 {
    font-size: 2em;
    line-height: 1.19;
    /* fix for ie border-radius bug, eliminates extra border line */
}

.article-header h1+a+h2 {
    margin-top: .5em;
}

.border-radius-3 {
    border-radius: 3px;
}

.border-radius-top-3 {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.border-radius-top-6 {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.width-100 {
    width: 100%;
}

.lt-ie9 .ui-tooltip-princess {
    border: 1px solid #0054A0 !important;
}

.ui-tooltip-princess .ui-tooltip-tip {
    height: auto !important;
}

.ui-tooltip-princess .ui-tooltip-titlebar {
    background-color: #0054A0;
    font-size: 1.3846em;
    padding: 5px;
    font-weight: 700;
    color: #fff;
}

.ui-tooltip-princess .ui-tooltip-content {
    padding: 5px;
}

.ui-tooltip-princess .ui-tooltip-titlebar .ui-tooltip-title,
.ui-tooltip-princess .qtip-titlebar {
    font-weight: normal;
}

.ui-tooltip .ui-tooltip-tip canvas,
.qtip .qtip-tip,
.qtip .qtip-tip .qtip-vml,
.qtip .qtip-tip canvas {
    display: none !important;
}

/* MFP Lightboxes */

#popupTitleArea {
    background: none;
    padding: 20px 15px 0 !important;
    text-transform: uppercase;
}

.mfp-content .article-header {
    background: none;
}

.mfp-content #popupContent {
    padding: 5px;
    overflow: hidden;
}

.ez-validation-messages .mfp-content #popupContent {
    padding: 20px 15px 5px;
}

.finished-submit .mfp-content #popupContent {
    padding-top: 0;
}

body.ez-iframed {
    background: white;
}

.ez-iframed .mfp-content {
    max-width: 880px;
}

.ez-iframed .ez-mobile-filter-lightbox .mfp-content,
.ez-iframed .ez-waiting-lightbox .mfp-content {
    vertical-align: top;
}

.ez-iframed .ez-mobile-filter-lightbox .mfp-container {
    top: 0 !important;
}

.ez-iframed .ez-waiting-lightbox .mfp-content {
    margin-top: 60px;
}

.ez-iframed .mfp-wrap {
    overflow-y: hidden !important;
}

.ez-iframed .mfp-wrap.ez-tnc-lightbox {
    overflow-y: auto !important;
}

.ez-iframed .mfp-close-wrapper {
    position: absolute;
    top: -20px;
    left: auto;
}

.mfp-content .droptab-menu {
    padding-left: 0 !important;
    padding-top: 0 !important;
}

.pc-icons.view-cruise {
    background: none;
    text-indent: 0;
    height: 20px;
    width: 20px;
    color: #FFF;
}

.pc-icons.view-cruise svg {
    float: left;
    height: 21px;
    width: 21px;
    position: relative;
    top: -2px;
    left: -1px;
}

.headerbar-btn.my-account .headerbar-btn-icon {
    height: 22px;
    width: 15px;
    position: relative;
    top: -1px;
}

#ezair-header {
    background-color: #00468C;
    padding: 0;
    box-sizing: border-box;
}

body:not(.product-PC) #ezair-header {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .4);
}

.ezair #princess-header,
.ezair-classic #princess-header {
    display: block;
}

.ezair #princess-header #touch-menu,
.ezair-classic #princess-header #touch-menu,
#princess-header .headerbar-menu .menu {
    display: none;
}

.lt-ie9 .ezair #header>.inner-wrapper,
.lt-ie9 .ezair-classic #header>.inner-wrapper {
    min-height: 70px;
    height: 70px;
}

.product-HA #ezair-header,
.product-PA #ezair-header,
.product-CCL #ezair-header,
.product-CL #ezair-header,
.product-CU #ezair-header,
.product-SB #ezair-header {
    background: white;
}

.product-HA #ezair-header-bottom,
.product-PA #ezair-header-bottom,
.product-CCL #ezair-header-bottom,
.product-CL #ezair-header-bottom,
.product-CU #ezair-header-bottom,
.product-SB #ezair-header-bottom {
    display: none;
}

.ezair-header-inner {
    max-width: 990px;
    margin: 0 auto;
    height: 47px;
}

.mfp-content .droptab-menu {
    background-color: transparent;
    border-bottom: 2px solid #e6e6e6;
}

#ez-previous-summary .itn-seat-list,
#ez-previous-summary .ez-details-change-link,
#ez-previous-summary .itn-dtl-cabin-class-msg .red {
    visibility: hidden;
    display: none;
}

.ez-header-logo {
    margin: 10px 0 10px 10px;
    background: top left/auto 100% no-repeat;
    text-indent: -999em;
    height: 27px;
    width: 60px;
    float: left;
}

.headerbar-menu {
    float: right;
    margin-right: 15px;
}

.product-PC .ez-header-logo {
    background-image: url('/images/global/logo_seawitch-icon-white.png');
}

.product-HA .ez-header-logo {
    background-image: url('/images/airbook/header/hal-logo-sm.png');
}

.product-PA .ez-header-logo {
    background-image: url('/images/airbook/PA_PA_logo.png');
}

.product-CU .ez-header-logo {
    background-image: url('/images/airbook/header/cunard-logo-sm.png');
}

.product-CCL .ez-header-logo,
.product-CL .ez-header-logo {
    background-image: url('/images/airbook/header/carnival-logo-sm.png');
}

.product-SB .ez-header-logo {
    background-image: url('/images/airbook/header/seabourn-logo-sm.png');
}

.headerbar-btn {
    float: right;
}

body:not(.product-PC) .headerbar-btn:not(.switch-guests) .headerbar-btn-link:hover,
body:not(.product-PC) .headerbar-btn:not(.switch-guests) .headerbar-btn-link:focus {
    background: #CCC;
}

.ezair #princess-header,
.ezair-classic #princess-header {
    display: block;
}

.ezair #princess-header #touch-menu,
.ezair-classic #princess-header #touch-menu,
#princess-header .headerbar-menu .menu {
    display: none;
}

.product-HA #ezair-header-bottom,
.product-PA #ezair-header-bottom,
.product-CCL #ezair-header-bottom,
.product-CL #ezair-header-bottom,
.product-CU #ezair-header-bottom,
.product-SB #ezair-header-bottom {
    display: none;
}

#ez-previous-summary .itn-seat-list,
#ez-previous-summary .ez-details-change-link,
#ez-previous-summary .itn-dtl-cabin-class-msg .red {
    visibility: hidden;
    display: none;
}

.itn-seat-list-btn,
.ez-select-seat-btn {
    display: none;
}

/* no header in iframe */
.ez-iframed #ezair-header {
    display: none;
}

.ezair-no-cp #steps-bar {
    margin-top: 4em;
}

.has-originating-system-link #steps-bar {
    padding-bottom: 1em;
}

.ezair-no-cp #container {
    padding-bottom: 4em;
}

#container {
    min-height: 75em;
}

.ez-iframed #container {
    min-height: 0;
}

.ez-iframed.ezair-results #container {
    min-height: 700px;
}

.ez-iframed #ez-flight-selection .article-container {
    min-height: 400px;
}

.ezair #content {
    padding: 40px 0;
}

.ezair.ezair-search #container.ezair-modify-home #cp-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

.ezair-search #content {
    padding: 0;
}

.ezair.ez-iframed #content {
    padding: 0;
    margin-top: 15px;
}

.ezair-error #steps-bar {
    display: none;
}

#container.ezair-bg {
    background-color: #0d2f56;
    background-image: url(/images/airbook/ezair-bg.jpg);
    background-position: top center;
    background-repeat: no-repeat;
}

.product-CCL #container.ezair-bg,
.product-CL #container.ezair-bg {
    background-color: #0065a2;
    background-image: url(/images/airbook/CCL-bg.jpg);
}

.product-PA #container.ezair-bg {
    background-color: #112251;
    background-image: url(/images/airbook/PA-bg.jpg);
}

.ez-iframed #container.ezair-bg {
    background: white;
    box-shadow: none;
}

.ezair .cp-touch-menu {
    z-index: 1000;
}

.ez-logo {
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 700 !important;
    font-size: 2.1em;
    color: #5e5e5e;
    letter-spacing: -1px;
}

.ez-logo-blue {
    color: #177ca3;
}

.ez-logo-reg {
    font-size: 0.666666em;
    top: -0.5em;
    font-weight: 400;
    letter-spacing: -.1em;
}

.ez-waiting-title>.ez-logo-reg {
    top: -0.2em;
}

#ez-search-sale-banner {
    background-color: #bd0000;
    min-width: 170px;
}

.ez-search-sale-title {
    display: none;
    border-bottom: 1px solid #FDB0B0;
    padding-bottom: 2px;
}

.ez-search-sale-price {
    padding-top: 2px;
}

.ez-search-sale-msg {
    border-top: 1px solid white;
}

#sale-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: inline;
    margin: 0;
    text-transform: none;
    text-shadow: none;
    box-shadow: none;
}

#sale-button:focus {
    outline: -webkit-focus-ring-color auto 5px;
}

#tab-container {
    min-height: 20em;
}

.ez-iframed #tab-container {
    border-radius: 0;
}

.ez-iframed #tab-container #ez-dpt-summary {
    border-radius: 0;
}

.ez-iframed .article-header {
    border-radius: 0 !important;
}

.ezair-search.autoair-flow .full-tab {
    display: none;
}

#booked-flight-header-modify {
    position: relative;
}

.booked-flight-header-details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    margin: auto;
}

.booked-flight-header-details-itin .roundtrip-note {
    margin-bottom: -10px;
    display: block;
}

.booked-flight-header-details-itin span {
    letter-spacing: 0 !important;
}

.booked-flight-header-details-itin span.gotham-book-it {
    font-size: 24px;
}

.ezair .full-tab {
    margin-bottom: -15px;
    padding-bottom: 3px;
}

.article-header .droptab-container {
    margin-bottom: 0;
    padding-top: 15px;
}

#ez-autoair .droptab-container {
    margin-bottom: 15px;
    padding: 0 15px;
}

.ezair-search .droptab-container-disabled {
    display: none;
}

.droptab-disabled {
    margin-right: -15px;
    margin-left: -15px;
}

.droptab-no-margin li+li {
    margin-left: 0;
}

.ez-quote-only-msg {
    max-width: 35em;
}

.ez-info-item {
    width: 17em;
    text-align: left;
    margin: auto;
}

.ez-info .ez-icon {
    background-image: url(../../images/airbook/ezair-info-icons.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    width: 16px;
    height: 30px;
}

.no-svg .ez-info .ez-icon {
    background-image: url(../../images/airbook/ezair-info-icons.png);
}

.ez-info-booking .ez-icon {
    background-position: -21px 0;
    width: 37px;
}

.ez-info-protection .ez-icon {
    background-position: -65px 0;
    width: 37px;
}

.ez-info-lightbox-container #popupContent {
    padding: 20px !important;
}

.ez-info-lightbox-container .title {
    font-size: 2.153846153846154em;
    /*28px*/
}

.ez-info-lbox-item {
    padding: 0 7px;
}

.ez-info-lbox-item:first-child {
    padding-left: 0;
}

.ez-info-lbox-item:last-child {
    padding-right: 0;
}

.ez-info-lbox-item-inner {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, .3);
    padding: 20px;
    height: 226px;
}

.ez-info-lightbox .ez-icon {
    background-image: url(../../images/airbook/ezair-info-lightbox-icons.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    margin: auto;
    width: 110px;
    height: 70px;
}

.no-svg .ez-info-lightbox .ez-icon {
    background-image: url(../../images/airbook/ezair-info-lightbox-icons.png);
}

.ez-info-lbox-booking .ez-icon {
    background-position: -112px 0;
}

.ez-info-lbox-protect .ez-icon {
    background-position: -224px 0;
}

.ez-info-lbox-quote .ez-icon {
    width: 150px;
    background-position: -335px 0;
}

.ez-info-lbox-support .ez-icon {
    width: 100px;
    background-position: -486px 0;
}

.ez-info-lbox-custom .ez-icon {
    width: 166px;
    background-position: -594px 0;
}

.ez-no-booking {
    border: 1px solid #d0d1d2 !important;
}

.ez-no-booking-icon {
    width: 44px;
    height: 44px;
    background-image: url(../../images/airbook/print-circle.svg);
    display: inline-block;
    background-repeat: no-repeat;
}

.no-svg .ez-no-booking-icon {
    background-image: url(../../images/airbook/print-circle.png);
}

.ez-info-row2 .ez-icon {
    background-image: url(../../images/airbook/ezair-info-lightbox-icons.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    width: 30px;
    height: 35px;
}

.ez-info-row2-quote .ez-icon {
    background-position: -396px 0;
}

.ez-info-row2-support .ez-icon {
    background-position: -521px 0;
}

.ez-info-row2-custom .ez-icon {
    background-position: -660px 0;
}

#search {
    overflow: visible !important;
}

.no-rgba .ezair .full-tab {
    background: #ebebeb;
}

.ezair .droptab-menu.droptab-mobile-menu {
    margin-bottom: 0;
    padding: 0;
}

.ezair-search #steps-bar {
    display: none;
}

.ezair .article-container {
    border-radius: 0;
}

.article-container-secondary {
    margin-top: 20px;
}

.inline-field+.inline-field {
    margin-left: 0;
}

.inline-field.checkbox {
    padding-right: 2em;
}

.terms-checkbox-list {
    padding-left: 0 !important;
}

.ez-search-booked {
    border-top: 1px solid #d0d1d2;
}

#ez-search-filters .checkbox input {
    margin-left: -1.5em;
    float: left;
}

.ez-search-guest-set .form-field input,
.ez-search-guest-set .form-field label {
    margin-bottom: 10px;
}

.autoair-flow #ez-search-passengers {
    display: none;
}

#ez-search-passengers .checkbox,
#ez-rslt-filters .checkbox {
    padding-left: 1.7em;
}

#ez-flight-filter .itn-seat-list {
    display: none;
}

#ez-flight-filter .ez-flight-filter-header {
    padding-left: 30px;
}

#ez-flight-filter .ez-flight-filter-header .ez-select-all-flights-container {
    display: flex;
}

#ez-flight-filter .itn-details {
    border: none;
    padding-left: 15px;
    padding-right: 30px;
    padding-top: 15px;
}

#ez-flight-filter .itn-dtl-leaving {
    border: 1px solid #d0d1d2;
    width: 95%;
}

#ez-flight-filter .itn-dtl-leaving .deactivate {
    display: none;
}

#ez-flight-filter .departure-flight-container {
    display: flex;
}

#ez-flight-filter .return-flight-container {
    display: flex;
}

#ez-first-flight {
    width: 5%;
}

#ez-second-flight {
    width: 5%;
}

.ez-select-all-lable {
    padding-left: 12px;
}

.passenger-name-lable {
    padding-left: 10px;
}

#ez-flight-filter .itn-dtl-returning {
    border: 1px solid #d0d1d2;
    width: 95%;
}

#ez-flight-filter .itn-dtl-returning .deactivate {
    display: none;
}

.return-link {
    background: none !important;
    border: none;
    font-family: arial, sans-serif;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}

.ezair .ez-search-location-radio {
    margin-right: 10px;
}

.ezair .ez-5-radio {
    margin-right: 5px;
    margin-left: 5px;
}

.ezair .name-confirm-radio {
    margin-top: 12px;
    margin-right: 20px;
    display: inline-block;
    ;
}

.ezair .name-confirm-disclaimer-header {
    position: relative;
    right: 25px;
}

.ezair .margin-top-0 {
    margin-top: 0;
}

.ez-search-guest-set label.error {
    display: none !important;
}

#ez-search-filters {
    background-color: #007ba3;
    padding-top: 35px;
    padding-bottom: 10px;
    box-shadow: none;
    position: relative;
}

#ez-search-filters legend {
    position: absolute;
    top: 10px;
    color: white;
}

#ez-search-filters select {
    min-width: 13em;
}

.form-field.ez-horizontal {
    display: block;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
}

.ez-horizontal label {
    color: white;
    display: inline-block;
    width: 25%;
}

.ez-horizontal select {
    width: 70%;
}

.checkbox.ez-horizontal {
    padding-left: 0.769223em;
}

.checkbox.ez-horizontal label {
    padding-left: 26%;
    width: auto;
}

#ez-search-filters .checkbox {
    padding-left: 25%;
}

.form-checkbox-wrapper {
    margin-left: 11px;
}

.checkbox .form-checkbox-wrapper label {
    padding-left: 0;
}

#ez-search-filters .checkbox .form-checkbox-wrapper input {
    float: none;
    margin-left: 0;
}

#ez-ship-bg {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: 80px;
    height: 55px;
    top: 21px;
    background-image: url(/images/airbook/sprite_air-icons.svg);
    background-position: 10px -201px;
}

.lt-ie9 #ez-ship-bg {
    background-image: url(/images/airbook/sprite_air-icons.png);

}

#ez-search-depart .ez-search-dir-header:before {
    border-radius: 0 6px 0 0;
}

#ez-search-return .ez-search-dir-header:before {
    border-radius: 6px 0 0 0;
}

.ez-search-dir-container.deactive {
    display: none;
}

.deactive .ez-search-dir-header:before,
.deactive .ez-search-dir-body:before,
.lt-ie9 .deactive .veil,
.deactive .deactive-target:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 1;
}

.deactive .deactive-target:before {
    background: rgba(255, 255, 255, .6);
}

.deactive .deactive-target.light-blue-bg:before {
    background: rgba(229, 243, 253, .7);
}

.ez-search-dir-container-empty .deactive-target.ez-search-dir-header:before {
    display: none;
}

.lt-ie9 .ez-search-dir-container-empty .ez-search-dir-header .veil {
    display: none;
}

.lt-ie9 .deactive .veil {
    background: rgb(255, 255, 255);
    zoom: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity=70);
}

.lt-ie9 .deactive .light-blue-bg .veil {
    background: rgb(229, 243, 253);
    /*background: rgb(228, 242, 252);*/
    zoom: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity=70);
}

.ez-flight-title {
    width: 100%;
}

#ez-search-dir-bg {
    position: relative;
    z-index: 1;
    box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, .3), 3px 2px 4px -2px rgba(0, 0, 0, .3), -3px 2px 3px -2px rgba(0, 0, 0, .3);
}

.no-boxshadow #ez-search-dir-bg {
    border-left: 1px solid #d0d1d2;
    border-bottom: 1px solid #d0d1d2;
    border-right: 1px solid #d0d1d2;
}

#ez-search-dir-bg:before {
    display: none;
}

#ez-search-bg-strip {
    position: absolute;
    background: #007ba3;
    top: 60px;
    bottom: 0;
    left: 49%;
    width: 2%;
    box-shadow: -4px 0px 5px -4px rgba(0, 0, 0, .3) inset;
}

.ez-search-dir-header {
    top: 1px;
    /* fixes ie problem of border radius causing elements underneath to bleed through on bottom */
}

#ez-search-fields .ez-flight-title {
    letter-spacing: 2px;
}

.ez-search-dir-container-empty .ez-flight-title {
    /*visibility: hidden;*/
    display: none;
}

#ez-depart-title-empty,
#ez-return-title-empty {
    margin-top: 0;
    display: none;
}

.ez-empty-direction-message {
    display: none;
    position: inherit;
    top: -2em;
    z-index: 10;
}

.ez-search-dir-container-empty .ez-empty-direction-message {
    display: block;
}

.ez-search-dir-container-empty #ez-depart-title-empty,
.ez-search-dir-container-empty #ez-return-title-empty {
    display: block;
}

.ez-search-dir-container-empty .ez-search-add-btn {
    display: none;
}

.ez-search-dir-container-empty .ez-cruise-info {
    visibility: hidden;
}

/*.ez-search-dir-container-empty .ez-search-dir-body {
    display: none;
}*/

.ez-search-dir-container-empty .ez-search-dir-header {
    min-height: 5em;
}

.ez-cruise-info {
    min-height: 2.8em;
}

.ez-search-dir-container .inline-field {
    padding-left: 0;
    padding-right: 0;
}

.ui-datepicker {
    width: 91%;
}

.ui-datepicker-warning {
    color: #FFF;
    background: #bd0000;
    padding: 15px;
}

#ez-search-submit .button,
.ez-big-green-btn.button {
    padding: 7px 55px;
}

#ez-search-submit {
    display: flex;
    place-content: end;
    align-items: center;
}

.ez-big-button {
    padding: 0.3em 0.7em;
}

.mc-validation-hidden {
    display: none;
}

/*For Worry Free Booking Messages Lightbox*/
#ez-worry-free-notification-container {
    width: 100%;
    position: absolute;
    z-index: 99;
}

.ez-worry-free-row-block {
    text-align: center;
    margin: 0 auto;
}

.ez-worry-free-info {
    width: 192px;
    text-align: left;
}

.ez-worry-free-icon-info {
    position: relative;
    top: 2px;
}

.ez-icon-booking-lock {
    margin-bottom: 3%;
}

.ez-fare-chart-icon-info {
    position: absolute;
    right: 2px;
    top: 2px;
}

#ez-manage-form-back-btn.button {
    margin: 0.5em 1em;
    border: solid 1px #999;
    padding: 7px 77px;
}

/* for generic baggage template*/
#genericBaggageContainer {
    border-top: 10px solid #0054a0;
    border-bottom: 10px solid #009dd2;
}

.bold {
    font-weight: 700;
}

.no-border-focus:focus {
    outline: none;
}

/* Fly with Friends */

#ez-preview-flights-waiting img {
    width: 64px;
}

.ez-preview-oneway .itn-route {
    min-height: auto !important;
}

.mfp-loading-spinner img {
    width: 64px;
}

.ez-fwf-booking-search-wrapper {
    width: 14em;
}

#ez-fwf-booking-search {
    max-width: 175px;
}

label.ez-fwf-booking-search-label {
    vertical-align: top;
    padding: 12px 5px 3px 0;
}

label[for='ez-fwf-booking-search'].error {
    position: absolute;
    width: 300px;
}

.ez-search-fwf-wrapper label.error {}

/* Datepicker */

input.datepicker {
    background-image: url(/images/airbook/icon-calendar.png);
    background-repeat: no-repeat;
    background-position: 5px 50%;
    padding-left: 30px;
}

.ui-datepicker {
    width: 17em;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, .7);
    border: none;
    padding: 0;
    position: absolute;
    bottom: -100px;
    left: 100%;
    z-index: 1;
    max-width: 108%;
}

.ui-datepicker .ui-datepicker-header {
    padding: .4em 0;
    background: #00223e;
    border: none;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    top: .5em;
}

.ui-datepicker td:first-child {
    padding-left: 6px;
}

.ui-datepicker td:last-child {
    padding-right: 6px;
}

.ui-datepicker thead {
    color: #00223e;
}

.ui-datepicker th {
    padding: 0.4em 0.3em;
}

.ui-datepicker td a {
    text-align: center;
    padding: 0.12em;
}

.ui-datepicker .ui-state-default {
    background: #e5f3fd;
    text-align: center;
}

.ui-datepicker .ui-state-active {
    background-color: white;
}

.ui-datepicker .ui-state-hover {
    background: white;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
    background: white;
}

/* Search Results */

/*.ui-tooltip {
    display: none !important;
}*/
/*.mfp-bg.mfp-ready {
    opacity: .8;
}*/

.ezair-results .ez-results-hidden {
    display: none;
}

.ezair-guest-info .ez-guest-info-hidden {
    display: none;
}

#princess-header+#steps-bar {
    padding-top: 2em;
}

.ez-iframed .ez-fare-chart-lightbox .mfp-container,
.ez-iframed .ez-dpt-details-lightbox .mfp-container {
    top: 20px !important;
}

.ez-fare-chart td,
.ez-fare-chart th {
    border: 2px solid white;
}

.ez-fare-chart th span {
    width: 100%;
    height: 100%;
    display: block;
    padding: 4px 5px;
}

#ez-chart-flex-header span {
    border-radius: 3px 0 0 0;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

#ez-chart-rstr-header span {
    border-radius: 0 3px 0 0;
}

.ez-fare-chart th {
    text-align: center;
    border: 0;
}

.ez-fare-chart td {
    padding: 8px 5px;
    max-width: 18em;
}

.ez-chart-checkmark {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    color: white !important;
}

#ez-search-fwf-itns-fares .itn-route,
#ez-search-fwf-itns-fares .ez-fare-card {
    display: table-cell;
    height: 1px;
    float: none !important;
    vertical-align: top;
}

#ez-rslt-main-error {
    overflow: hidden;
}

#ez-rslt-sidebar {
    display: none;
}

#ez-rslt-filters {
    font-size: 1.153846153846154em !important;
}

.ez-rslt-fltr-header {
    display: none;
}

.mfp-bg.ez-mobile-filter-lightbox.mfp-ready {
    opacity: .9;
}

.ez-mobile-filter-lightbox .mfp-container {
    padding: 30px 5px;
}

#ez-rslt-fltr-mobile-cancel {
    text-decoration: none;
}

.ez-fltr-mobile-header {
    background-color: #0d2f56;
    border-bottom: 1px solid #32516a;
    width: 100%;
}

.ez-rslt-fltr-menu {
    display: none;
    /*margin: 1em 0;*/
    margin: 0;
    padding-top: 1em;
    padding-bottom: 1em;
    background: #E5F3FD;
}

.ez-rslt-fltr-menu.active {
    display: block;
}

#ez-rslt-fltr-sortby label {
    padding-left: 10px;
}

#ez-rslt-filters .checkbox label {
    padding-left: 5px;
}

.ez-rslt-fltr-menu input[type='radio'] {
    margin-left: -.9em;
    float: left;
}

#ez-rslt-filters .ez-rslt-fltr-menu input[type='checkbox'] {
    margin-left: -12px;
}

.ez-fltr-mobile-header .arrow-toggle {
    border-left-width: 5px;
    border-right-width: 5px;
    border-top-width: 8px;
    top: 5px;
    float: right;
    transition: 100ms linear all;
}

.ez-fltr-mobile-header.active .arrow-toggle {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

#ez-fltr-apply-btn {
    padding: 0.3076em 3em;
    display: block;
    margin: .5em auto 1.5em;
}

#ez-rslt-sidebar .ez-help-info {
    padding-top: 20px;
}

#ez-rslt-sidebar .ez-help-title {
    display: block;
}

/*#ez-rslt-sidebar .ez-help-phone {
    white-space: nowrap;
}*/
.ez-rslt-found {
    text-align: center;
}

.ez-rslt-found h3 {
    letter-spacing: 0;
}

#ez-rslt-topbar .ez-help-info {
    width: 100%;
    text-align: center;
    padding: 10px;
}

#ez-rslt-topbar .ez-help-title {}

#ez-fltr-mobile-open {
    margin-right: 4%;
    margin-bottom: 10px;
    padding-left: 2em;
    padding-right: 2em;
}

#ez-rslt-sort-select {
    display: none;
}

.itn-route {
    position: static;
}

.itn-route-header {
    position: relative;
}

.phone-number {
    color: inherit;
    font-size: inherit;
    text-decoration: none;
}

/* TESTING ONLY. REMOVE BEFORE LAUNCH */

.itn-route-header:after {
    position: absolute;
    right: 12px;
    top: 0;
    bottom: 0;
}

div[data-segmentflag=""] header:after {
    content: "Segment Type: Regular";
}

div[data-segmentflag="B"] header:after {
    content: "Segment Type: Block";
}

div[data-segmentflag="C"] header:after {
    content: "Segment Type: Charter";
}

.ez-fare-select-btn button:after {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 120%;
    color: #000;
    font-size: 0.7em;
}

.fare-button {
    display: flex;
    justify-content: center;
}

.economy-fare-card .sale-rbn {
    margin-right: -34px;
}

.fare-cards-row {
    height: 490px;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    margin-right: 45px;
    margin-left: 45px;
    overflow: hidden;
}

.just-arrow {
    font-size: x-large;
}

.scroll-container {
    position: relative;
    overflow: hidden;
}

.scroll-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #FFFFFFD9;
    /* semi-transparent background */
    color: #0054A0;
    border: none;
    cursor: pointer;
    z-index: 1;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 2px;
}

.scroll-arrow.left {
    left: 0;
    display: none;
    /* Initially hide the left arrow */
}

.scroll-arrow.right {
    right: 0;
}

.ez-fare-select-btn button[data-flightmatchkey^="OW"]:after {
    content: "OW";
}

.ez-fare-select-btn button[data-flightmatchkey^="RT"]:after {
    content: "RT";
}

/* END TESTING ONLY */

.itn-route-limited .itn-route {
    box-shadow: none;
}

.itn-stop-container {
    padding-top: 15px;
}

.itn-origin {
    text-align: left;
    /*padding-left: 15px;    */
    padding-left: 10%;
    padding-right: 0;
    width: 45%;
    position: absolute;
}

.ezair .itn-layover {
    position: relative;
    top: -11px;
    padding: 0 5%;
    line-height: 16px;
    display: inline-block;
    z-index: 2;
}

.ez-itin-data[data-stops="3"] .itn-layover {
    padding: 0 3.5%;
}

.ez-itin-data[data-stops="4"] .itn-layover,
.ez-itin-data[data-stops="5"] .itn-layover,
.ez-itin-data[data-stops="6"] .itn-layover {
    padding: 0 1.5%;
}

.itn-stop-time {
    display: none;
}

.itn-stop-airport {
    font-size: 1.384615384615385em;
    text-decoration: none;
}

.ezair .itn-final {
    text-align: right;
    /*padding-right: 15px;*/
    padding-right: 10%;
    padding-left: 0;
    width: 45%;
    position: absolute;
    top: 15px;
    right: 0;
}

.ez-itn-details .next-day-full,
.itn-route-wrapper .next-day-segment {
    display: none;
}

/* in multicity results, the first leg shouldn't show the whole itinerary's "arrive next day" message */
.ez-multicity-search .itn-row:first-child .next-day-full {
    display: none;
}

.itn-stop-container>.row {
    min-height: 9em;
}

.ezair .itn-line {
    /* width: 86%;
    left: 7%;*/
    width: 74%;
    left: 13%;
    top: 0;
}

.itn-total-duration {
    display: none;
}

.ez-mobile-menu {
    border-radius: 0 !important;
    color: white;
}

.itn-route-limited .itn-row:last-child:nth-of-type(even) {
    border: 0;
}

.itn-route-limited .itn-row:nth-of-type(1) {
    border-radius: 4px 4px 0 0;
}

.ez-fwf-check-another.plain-text-btn {
    display: block;
    margin: 3em auto 0;
    text-decoration: underline;
}

/*.ez-fwf-check-another.button {
    text-decoration: none;
}*/
.itn-oper-details span:after {
    content: ",";
}

.itn-oper-details span:last-child:after {
    content: "";
}

/* ie8 can't target :last-child, so use nbsp's instead of commas */
.lt-ie9 .itn-oper-details span:after {
    content: "\00a0\00a0";

}

.loading-16 {
    max-width: 16px !important;
}

.ezair .itn-carrier-MLT {
    max-height: none;
    max-width: 27px;
}

.ez-rslt-loading .itn-container-wrapper {
    visibility: hidden;
}

.ez-rslt-loading .itn-route,
.ez-rslt-loading .ez-card-cell {
    box-shadow: none;
}

.ez-rslt-loading #ez-loading-msg {
    background: url(/images/airbook/ez-loading.gif) no-repeat top center;
    background-size: 64px 64px;
    position: absolute;
    z-index: 9999;
    text-align: center;
    top: 100px;
    width: 100%;
    height: 300px;
    padding-top: 80px;
}

.lt-ie9 .ez-rslt-loading #ez-loading-msg {
    background: url(/images/airbook/ez-loading_64.gif) no-repeat top center;
}

.ez-header-links a {
    padding: 0 5px 2px 7px;
}

.ez-header-links .ez-back-to-host-link {
    padding-left: 0;
    padding-top: 8px;
    display: block;
    border-left: 0;
}

body[data-view="select-return"] #ez-dpt-summary .ez-back-to-host-link {
    display: none;
}

.ez-header-links a:first-child {
    padding-left: 0;
}

.ez-header-links a+a {
    border-left: 1px solid #a1a1a1;
}

.ez-rslt-back {
    letter-spacing: -0.01px !important;
}

/* hide the "change depart flight" link on the depart view */
body[data-view="select-depart"] .ez-rslt-back {
    display: none;
}

.ezair-results #container .article-header {
    padding-bottom: 60px;
}

.ezair-results .article-header.results-error {
    padding-bottom: 40px;
}

.article-container.results-error {
    background-color: white;
}

#ez-rslt-main-error .ez-help-info {
    padding-top: 15px;
}

#ez-rslt-main-error .ez-help-title {
    display: block;
}

#ez-rslt-topbar {
    margin-top: -65px;
}

.hidden-faretype,
.hidden-stopcount,
.hidden-departtime,
.hidden-airline,
.hidden-filters,
.hidden-msg {
    display: none;
}

.offscreen {
    position: absolute;
    left: -9999px;
    top: -9999px;
}

.show-msg {
    display: block !important;
}

.ez-filter-disabled {
    color: #a6a6a6;
}

.ez-fare-type-header {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.ez-fare-card-flex .ez-fare-type-header,
.ft-flexible .ft-title,
#ez-chart-flex-header span {
    background-color: #0D2F56;
}

.ez-fare-card-restricted .ez-fare-type-header,
.ft-restricted .ft-title,
#ez-chart-rstr-header span {
    background-color: #5a5a5a;
}

.ez-card-cell {
    height: 100%;
    min-height: 16em;
    position: relative;
}

.itn-container-wrapper[data-flex-discount='true'] .ez-card-cell,
.itn-container-wrapper[data-rstr-discount='true'] .ez-card-cell {
    min-height: 18.5em;
}

/*.autoair-flow .itn-container-wrapper[data-flex-discount='true'] .ez-card-cell, .autoair-flow  .itn-container-wrapper[data-rstr-discount='true'] .ez-card-cell {
    min-height: 21.5em;
}*/
.lt-ie9 .ez-card-cell {
    height: auto;
    min-height: 18em;
}

.autoair-flow .ez-card-cell,
.ez-card-cell-tall {
    height: 100%;
    min-height: 18.5em;
    position: relative;
}

.ez-saved-flight .ez-fare-select-btn {
    display: none;
}

.ez-saved-flight #ez-autoair {
    margin-bottom: 0;
}

.itn-container-wrapper[data-flex-sale="true"] .ez-card-cell,
.itn-container-wrapper[data-rstr-sale="true"] .ez-card-cell,
.itn-container-wrapper[data-flex-carrier-sale="true"] .ez-card-cell,
.itn-container-wrapper[data-rstr-carrier-sale="true"] .ez-card-cell {
    min-height: 16em;
}

.ez-fare-card .sale-rbn {
    margin-right: -4px;
}

.ez-fare-card .sale-rbn-banner:after {
    border-width: 2px;
}

.ez-fare-info {
    padding: 10px;
    clear: both;
    line-height: 1.2;
    margin-top: 0.5em;
}

.ez-fare-no-book {
    line-height: 1.2;
}

.itn-container-wrapper[data-flex-sale="true"][data-rstr-sale=""] .ez-fare-card-restricted .ez-fare-info,
.itn-container-wrapper[data-flex-carrier-sale="true"][data-rstr-sale=""] .ez-fare-card-restricted .ez-fare-info,
.itn-container-wrapper[data-rstr-carrier-sale="true"][data-flex-sale=""] .ez-fare-card-flex .ez-fare-info,
.itn-container-wrapper[data-rstr-sale="true"][data-flex-sale=""] .ez-fare-card-flex .ez-fare-info {
    margin-top: 2.5em;
}

.ez-fare-sale .ez-fare-total,
.ez-fare-sale .ez-fare-additional {
    color: #bd0000;
}

.ez-fare-total:first-child {
    padding-top: 15px;
}

.sale-rbn+.ez-fare-info {
    margin-top: 0 !important;
}

.ez-fare-select-btn {
    position: absolute;
    bottom: 1em;
    width: 100%;
}

.covid-msg {
    padding-top: 10px;
    padding-right: 7px;
}

.covid-msg-cnt {
    padding-top: 10px;
}

.pc-icons.covid-hazard {
    padding-right: 30px;
    margin: 12px;
}

.lt-ie9 .ez-fare-select-btn {}

.ez-fare-card .sale-rbn {}

.sale-rbn-right-8 {
    margin-right: -8px;
}

#ez-rslt-main .ez-fare-additional {
    display: none;
}

#ez-rslt-return .ez-fare-card-empty {
    display: none;
}

#ez-rslt-return .ez-fare-card,
.ez-filtered-flexible .ez-fare-card,
.ez-filtered-restricted .ez-fare-card,
.itn-solo-farecard .ez-fare-card {
    left: 25%;
}

#ez-rslt-return .ez-fare-total,
#ez-rslt-return .ez-fare-roundtrip-msg,
#ez-rslt-return .ez-fare-base {
    display: none;
}

#ez-search-fwf-wrapper .ez-fare-additional,
#ez-search-fwf-wrapper .ez-fare-additional {
    display: none;
}

.ez-preview-flights-tab .ez-faretable-link {
    display: none;
}

.ez-preview-flights-tab {
    display: flex;
    width: 100%;
}

.itn-container-wrapper {
    position: relative;
    padding-bottom: 30px;
}

.ez-preview-itns .itn-container-wrapper+.itn-container-wrapper {
    margin-top: 75px;
}

.ez-preview-itns .itn-container-wrapper {
    margin-top: 50px;
}

.itn-container-wrapper {
    background: white;
    margin-bottom: 20px;
    position: relative;
    padding-bottom: 40px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .3);
}

#ez-autoair-new-top .itn-container-wrapper,
#ez-autoair-new-bottom .itn-container-wrapper,
#ez-autoair .itn-container-wrapper {
    padding-bottom: 50px;
}

.ez-itin-limited-wrapper.itn-container-wrapper {
    padding-bottom: 30px;
}

.itn-footer {
    position: relative;
    bottom: 0;
    background-color: #e5f3fd;

}

.itn-restrictions-link,
.itn-baggage-link,
.itn-details-btn {
    text-decoration: none;
    display: inline-block;
    margin: .25em 0;
}

.itn-restrictions-link:hover,
.itn-baggage-link:hover,
.itn-details-btn:hover {
    text-decoration: underline;
}

.ez-terms-list li+li {
    margin-top: .5em;
}

.ez-rslt-fltr-fieldset {}

.ez-rslt-fltr-fieldset .form-field {
    padding: .5em 1em .5em 0;
}

.ez-rslt-fltr-fieldset .checkbox input {
    margin-right: 5px;
}

.ez-fare-card-empty.ez-fare-card-flex .ez-fare-type-header {
    background-color: #566D89;
}

.ez-fare-card-empty.ez-fare-card-restricted .ez-fare-type-header {
    background-color: #767676;
}

.ez-filtered-restricted .ez-fare-card-flex,
.ez-filtered-flexible .ez-fare-card-restricted {
    display: none;
}

.ez-filtered-restricted.ez-filtered-flexible .ez-fare-card-flex,
.ez-filtered-restricted.ez-filtered-flexible .ez-fare-card-restricted {
    display: block;
}

.ez-preview-itns .ez-fare-card {
    /*left: 25%;*/
    margin: 0 auto;
    float: none;
    left: auto;
    width: 15em;
}

.ez-summary-sale {
    position: relative;
}

.sale-rbn-left {
    background-color: #bd0000;
    color: #fff;
    position: relative;
    padding: 3px 10px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, .6);
    display: inline-block;
}

.sale-rbn-left:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 0px;
    bottom: -8px;
    border-width: 4px;
    border-style: solid;
    border-color: #510404 #510404 transparent transparent;
}

.ez-summary-sale-rbn {
    position: absolute;
    box-shadow: none;
    margin-left: -18px;
}

.ez-summary-sale-rbn:after {
    display: none;
}

.ez-summary-savings-text {
    padding-left: 42px;
    padding-top: 3px;
}

.print-btn.plain-text-btn {
    text-decoration: underline;
}

.print-btn.plain-text-btn:hover {
    text-decoration: none;
}

.special-service-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    background: url(../../images/global/sprite_pc-icons.svg) -468px -18px no-repeat;
    display: inline-block;
    position: relative;
    top: 2px;
    left: 2px;
}

#ez-autoair-new-top .itn-full-farecard-wrapper,
#ez-autoair-new-bottom .itn-full-farecard-wrapper,
#ez-autoair .itn-full-farecard-wrapper {
    text-align: center;
}

#ez-autoair-new-top .ez-fare-card,
#ez-autoair-new-bottom .ez-fare-card,
#ez-autoair .ez-fare-card {
    width: 48%;
    padding: 5px 7px;
    max-width: 175px;
    display: inline-block;
    float: none;
    left: auto;
    vertical-align: top;
}

#ez-autoair-new-top .itn-solo-farecard .ez-fare-card,
#ez-autoair-new-bottom .itn-solo-farecard .ez-fare-card,
#ez-autoair .itn-solo-farecard .ez-fare-card {
    min-width: 175px;
}

#ez-autoair {
    background: rgba(235, 235, 235, .85);
}

#ez-autoair-new-top .itn-container-wrapper,
#ez-autoair-new-bottom .itn-container-wrapper,
#ez-autoair .itn-container-wrapper {
    max-width: 650px;
}

#ez-autoair .full-tab {
    background: transparent;
}

/* override drop tabs setting overflow hidden to keep shadows visible */
.autoair-tab-content {
    overflow: visible !important;
}

/*overriding old inline styles */
.ez-tnc-lightbox.mfp-ready #searchresultsbox {
    height: auto !important;
    overflow: auto !important;
    width: auto !important;
    float: none !important;
    background-color: white;
}

.ez-tnc-lightbox.mfp-ready #searchresultsbox table,
.ez-tnc-lightbox.mfp-ready #searchresultsbox td {
    width: 100% !important;
    background-color: white;
}

.ez-tnc-lightbox.mfp-ready .bluetitle56 {
    width: 100% !important;
    background-color: white !important;
}

.ez-terms-links-container li {
    display: inline-block;
    padding-right: 2em;
}

.ez-terms-container {
    padding: 20px 50px 10px;
    border: 1px solid #999999;
}

.ez-terms-container .form-field.checkbox,
.terms-checkbox {
    background-color: #e5f3fd;
    display: inline-block;
    padding-right: 40px;
    margin: 10px 0;
    border: 1px solid #d0d1d2;
}

.droptab-disabled {
    padding: 0 !important;
}

.droptab-disabled li {
    width: 100%;
    text-align: left !important;
}

.droptab-disabled li a {
    padding-left: 15px;
    cursor: default;
}

.droptab-disabled li.active:after {
    display: none;
}

.ez-dpt-summary-cell {
    text-align: center;
}

#ez-dpt-summary .ez-dpt-summary-cell:first-child {
    width: 24%;
}

#ez-dpt-summary .itn-carrier-logo {
    float: right;
    padding-right: 2px;
}

#ez-dpt-summary .itn-row {
    background-color: #ebebeb;
    min-height: 0;
    padding-bottom: 0;
    border-bottom: 0;
}

#ez-dpt-summary .itn-route-wrapper {
    border-top: 1px solid #d0d1d2;
    border-bottom: 1px solid #d0d1d2;
}

#ez-dpt-summary .itn-stop-img {
    background-color: #ebebeb;
}

#ez-dpt-summary .itn-oper-details {
    display: none;
}

#change-flight {
    font-size: 19px;
    margin-left: 20%;
    margin-bottom: 2%;
}

#current-flight-itin {
    width: 990px;
    background-color: #ebebeb;
    margin-top: 50px;
    border-radius: 10px;
    margin: auto;
}

.ez-dpt-summary-cell {
    text-align: center;
}

#current-flight-itin .itn-carrier-logo {
    float: right;
    padding-right: 2px;
}

#current-flight-itin .itn-row {
    min-height: 0;
    padding-bottom: 0;
    border-bottom: 0;
}

.seats-left-slot {
    height: 16px;
}

#current-flight-itin .itn-stop-img {
    background-color: #ebebeb;
}

#current-flight-itin .itn-oper-details {
    display: none;
}

.ez-dpt-summary-first-cell {
    width: 30%
}

.ez-dpt-summary-second-cell {
    width: 70%
}

.current-flight-itin .itn-total-duration {
    font-weight: bold;
}

#current-flight-itin .ez-dpt-summary-first-cell {
    padding-top: 22px;
}

#current-flight-itin .flight-heading {
    font-size: 28px;
}

#current-flight-itin .itn-row {
    background-color: #ebebeb;
    min-height: 0;
    padding-bottom: 0;
    border-bottom: 0;
}

#current-flight-itin .itn-carrier-logo {
    float: right;
    padding-right: 2px;
}

#current-flight-itin .itn-route-wrapper {
    min-height: 0;
    background-color: #ebebeb;
    padding-top: 0;
}

#current-flight-itin .itn-stop-img {
    background-color: #ebebeb;
}

#current-flight-itin .itn-oper-details {
    display: none;
}

#current-flight-itin .fare-button {
    height: 43px;
}

.ez-fare-inital-select-btn {
    position: absolute;
    bottom: 1em;
    width: 100%;
}

.fare-button {
    display: flex;
    justify-content: center;
}

#fare-cards-header {
    height: 67px;
    padding: 40px;
    width: 995px;
    background-color: #ebebeb;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#fare-cards-container {
    padding-top: 40px;
    place-content: center;
    margin-left: auto;
    display: grid;
}

#fare-cards-header-mobile {
    display: none;
}

.fare-cards {
    height: auto;
    padding: 25px;
    background-color: #B4CFE3;
    width: 995px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.economy-fare-card {
    width: 312px;
    height: 398px;
    display: inline-table;
    padding: 25px;
    margin: 5px;
    background: white;
    opacity: 1;
    margin-top: 10px;

}

.scroll-arrow.left {
    left: 0;
    display: none;
}

#container-fareCards.ezair-bg {
    background-image: url(/images/airbook/ezair-bg.jpg);
    background-color: #142e52;
    background-position: top center;
    background-repeat: no-repeat;
}

#container-fareCards #steps-bar {
    display: none;
}

.change-flight {
    background: none !important;
    border: none;
    color: white;
    text-decoration: underline;
    cursor: pointer;
}

.fare-cards-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
}

.button-container-fare-cards {
    margin-top: 15px;
}

.fare-class-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.baggage-message-0 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.class-feature-container {
    width: 260px;
    height: 172px;
}

.brand-feature {
    width: max-content;
    height: 20px;
    padding-left: 60px;
    padding-bottom: 23px;
    padding-top: 6px;
    display: flex;
}

.brand-feature-icon {
    color: #587F49;
    height: 16px;
    margin-right: 4px;
}

.ez-preview-itns .ez-card-cell {
    min-height: 17em;
}

#content .itn-full-farecard-wrapper[data-rstr-discount='true'] .ez-card-cell,
#content .itn-full-farecard-wrapper[data-flex-discount='true'] .ez-card-cell {
    min-height: 19.5em;
}

#ez-autoair .itn-full-farecard-wrapper[data-rstr-discount='true'] .ez-card-cell,
#ez-autoair .itn-full-farecard-wrapper[data-flex-discount='true'] .ez-card-cell {
    min-height: 20.5em;
}

.ez-preview-itns .ez-fare-roundtrip-msg {
    visibility: hidden;
}

.fwf-guest-name {
    position: absolute;
    top: -38px;
}

.fwf-guest-name span:not(:last-of-type)::after {
    content: ", "
}

.fwf-reset-guests-container {
    border-top: 1px solid #d0d1d2;
    padding-top: 20px;
    text-align: center;
}

/* Waiting Splash Screen */
.ezair-classic #ez-waiting * {
    box-sizing: border-box;
}

.ez-waiting-lightbox .mfp-container {
    padding: 0;
}

/* avoid flash of unstyled content in ie8 due to media queries loading with respond.js */
.lt-ie9 .ez-waiting-lightbox.mfp-bg.mfp-ready {
    background: #000000;
    opacity: .6;
    filter: alpha(opacity=60);
}

.lt-ie9 #ez-waiting {
    display: none;
}

.ez-waiting-lightbox.mfp-bg.mfp-ready {
    background: #5194c5;
    opacity: 1;
    filter: none;
}

.product-PC.booking-PC .ez-waiting-lightbox.mfp-bg.mfp-ready,
.product-PC.booking-JP .ez-waiting-lightbox.mfp-bg.mfp-ready,
.product-PC.booking-PA .ez-waiting-lightbox.mfp-bg.mfp-ready {
    background-color: #5e8fc1;
    background-image: url(../../images/airbook/waiting-clouds-bg-mobile.jpg);
    background-repeat: no-repeat;
    background-position: 50% 0;
}

.ez-waiting-logo-container {
    background: url(../../images/airbook/logo-shadow.png) center center no-repeat;
    width: 290px;
    height: 121px;
    background-size: contain;
    margin: 0 auto;
}

.ez-waiting-logo {
    background: url(../../images/airbook/logo-ezair.svg) center center no-repeat;
    width: 270px;
    height: 100px;
    background-size: contain;
    margin: 0 auto;
}

.lt-ie9 .ez-waiting-logo {
    background-image: url(../../images/airbook/logo-ezair.png);
}

.product-CCL .ez-waiting-lightbox.mfp-bg.mfp-ready,
.product-CL .ez-waiting-lightbox.mfp-bg.mfp-ready {
    background: white;
}

.ez-waiting-lightbox.mfp-bg.mfp-removing {
    opacity: 0 !important;
}

.ez-waiting-progress {
    visibility: hidden;
    opacity: 0;
}

.ez-waiting-plane,
.ez-waiting-progress-line {
    display: none;
}

.ez-waiting-title {
    font-size: 1.538461538461538em;
    /*20px*/
    padding-left: 20px;
}

.product-CCL .ez-waiting-title,
.product-CL .ez-waiting-title {
    padding-bottom: 8px;
    padding-left: 0;
}

.ez-waiting-logo-text {
    padding-top: 22px;
    display: inline-block;
    letter-spacing: 4px !important;
}

.product-CCL .ez-waiting-logo-text,
.product-CL .ez-waiting-logo-text {
    padding-top: 14px;
    letter-spacing: 2px !important;
}

.ez-waiting-logo-img {
    background-repeat: no-repeat;
}

.product-PC.booking-PO .ez-waiting-logo-img {
    background-image: url(../../images/airbook/logo-plane-sailing.svg);
    margin: -25px auto 30px;
    width: 198px;
    height: 142px;
}

.no-svg .product-PC.booking-PO .ez-waiting-logo-img {
    background-image: url(../../images/airbook/logo-plane-sailing.png);
}

.product-CCL .ez-waiting-logo-img,
.product-CL .ez-waiting-logo-img {
    background-image: url(../../images/airbook/logo-ccl-fly2fun.svg);
    width: 134px;
    background-size: 134px;
    height: 41px;
    vertical-align: top;
}

.no-svg .product-CCL .ez-waiting-logo-img,
.no-svg .product-CL .ez-waiting-logo-img {
    background-image: url(../../images/airbook/logo-ccl-fly2fun.png);
}

.product-CCL .ez-waiting-msg-text,
.product-CL .ez-waiting-msg-text {
    color: #004a98;
}

.ez-waiting-tagline {
    letter-spacing: 2px !important;
    position: relative;
    display: inline-block;
    font-size: 1.538461538461538em;
    /*20px*/
}

.ez-waiting-tagline-solo {
    letter-spacing: 3px !important;
    font-size: 1em;
    /*13px*/
}

.ez-waiting-tagline-line1 {
    display: block;
}

.ez-dash-line {
    width: 40px;
    height: 2px;
    background: white;
    position: absolute;
    top: 22%;
}

.ez-dash-line.ccl-blue {
    background-color: #004a98;
}

.ez-waiting-tagline-long .ez-dash-line {
    width: 30px;
}

.ez-dash-line-left {
    left: -23px;
}

.ez-waiting-tagline-long .ez-dash-line-left {
    left: -40px;
}

.ez-dash-line-right {
    right: -23px;
}

.ez-waiting-tagline-long .ez-dash-line-right {
    right: -40px;
}

.ez-waiting-benefits {
    display: none;
}

.ez-waiting-benefits.waiting-slider {
    display: block;
    height: 140px;
    padding: 15px 0;
    background-color: #DAE8F4;
    margin-top: 25px;
}

.lt-ie9 .ez-waiting-benefits.waiting-slider {
    background: #DAE8F4;
}

.ez-waiting-benefits .slides-container {
    height: 100%;
}

.waiting-slider .ez-waiting-benefits-item {
    height: 100%;
}

.waiting-slider .ez-waiting-benefits-item .ir {
    text-indent: 0;
    color: #DAE8F4;
}

.waiting-slider .ez-waiting-benefits-item::after {
    background-color: transparent;
    background-position: 50% 0;
    background-repeat: no-repeat;
    text-indent: -9999px;
}

.waiting-slider .slides-container-mobile .ez-waiting-benefits-item.ez-waiting-benefits-item-ralign::after {
    background-size: 40%;
}

.ez-waiting-benefits-desktop {
    padding: 0px 10px !important;
    margin: 0px 0.66%;
    width: 32%;
    min-height: 6em !important;
}

.ez-waiting-benefits-desktop-slides {
    width: 100%;
    text-align: center;
}

.ez-waiting-benefits-mobile-slides {
    min-height: 8em;
}

.ez-waiting-benefits-protection-position {
    background-position: 60% 0 !important;
}

.ez-waiting-benefits-lock-checkmark-position {
    background-position: 55% 0 !important;
}

.ez-waiting-benefits-lock-checkmark-size-mobile {
    background-size: 180px;
}

#ez-fare-summary .ez-next-step-msg {
    letter-spacing: 0;
}

#ez-fare-summary .need-help {
    font-family: var(--red-hat-text-bold);
    font-weight: 400;
    letter-spacing: 0;
}

.guest-flight-head h2 {
    padding: 15px 25px;
}

#modify-content h2,
#modify-content h3 {
    letter-spacing: 0;
}

.guest-name {
    text-transform: capitalize !important;
}

.depart-flight-airports,
.return-flight-airports {
    line-height: 1.5em;
    margin-bottom: 10px;
}

.booked-flight-header-details-itin {
    line-height: 2.2em;
}

.modify-from-airport+.modify-from-airport,
.modify-to-airport {
    display: none;
}

.modify-to-airport:last-child {
    display: inline-block;
}

.italic {
    font-style: italic;
}

.booked-flight-header-details-meta {
    border: 1px solid #919191;
    position: relative;
}

.booked-flight-meta-details {
    line-height: 1.9em;
}

body:not(.product-PC) .modify-from-airport,
body:not(.product-PC) .modify-to-airport,
body:not(.product-PC) .air-booking-number,
body:not(.product-PC) .cruise-departure {
    font-weight: bold;
}

body:not(.product-PC) .small-to {
    font-weight: normal !important;
}

.cell-buttons {
    display: flex;
    text-align: center;
    margin-top: 1px;
    /* IE Fix */
}

.cell-buttons div {
    flex: 1;
    border: 1px solid #0054A0;
    margin: 0 3px;
}

.cell-buttons div button,
.cell-buttons div a {
    height: 46px;
    line-height: 46px;
    margin: 0;
    padding: 0;
    width: 100%;
    border: none;
    display: inline-block;
    background: #E5F3FE;
    color: #0054A0;
    text-decoration: none;
}

.cell-buttons div button:not(.no-hover):hover,
.cell-buttons div a:not(.no-hover):hover {
    background: #0054A0;
    color: #FFF;
}

.cell-buttons div button:focus,
.cell-buttons div a:focus {
    outline: 1px dotted #0054A0;
    outline-offset: 1px;
}

.cell-buttons div button:active,
.cell-buttons div a:active {
    background: #333;
    color: #FFF;
}

.cell-buttons div:first-child {
    margin-left: 0;
}

.cell-buttons div:last-child {
    margin-right: 0;
}


.guest-options-row {
    display: table-row;
    border-bottom: 1px solid #E6E6E6;
    display: flex;
}

.text-align-vertical {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

input[type="checkbox"].select-all-passengers,
input[type="checkbox"].cancel-passenger {
    margin-right: 5px;
    display: inline-block;
}

input[type="checkbox"].select-all-passengers.savings,
input[type="checkbox"].cancel-passenger.savings {
    display: none;
}

.guest-options-thead {
    background: #E6E6E6;
    line-height: 27px;
    display: flex;
}

.guest-options-thead .guest-options-cell {
    padding: 10px;
}

.guest-options-thead .guest-options-cell h2 {
    letter-spacing: 0.5px !important;
    text-transform: none;
    margin: 0;
}

.guest-options-cell-item {
    margin: 5px;
    position: relative;
    margin-left: 28px;
}

.guest-options-cell {
    padding: 15px 10px;
    vertical-align: middle;
    position: relative;
    flex: 1;
    text-align: left;
    line-height: 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.guest-options-cell h3 {
    letter-spacing: 0;
}

.guest-options-cell.text-left {
    justify-content: left;
    flex: 0.8;
}

.guest-options-row:not(.thead) .guest-options-cell {
    min-height: 70px;
}

#popupContent .guest-options-row:not(.thead) .guest-options-cell {
    padding: 10px;
}

.guest-options-modify .temp-form {
    display: none;
}

.guest-options-modify .temp-form.show-selected {
    display: block;
    text-align: center;
}

.guest-options-modify .square.show-selected~.square.show-selected {
    margin-top: 10px;
}

.guest-options-modify .temp-form>div>* {
    display: none;
}

.guest-options-modify .temp-form .show-input {
    display: block;
    float: left;
}

.guest-options-modify .square.show-selected .itn-carrier-logo {
    display: inline-block;
    float: left;
    margin-top: 5px;
}

.guest-options-modify .temp-form div.show-input {
    line-height: 1.2em;
}

.guest-options-modify .open-temp-form {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: default;
    border: none;
    padding: 0;
    margin: auto;
    background: none;
}

.guest-options-modify .open-temp-form.text-button {
    display: none;
    padding: 10px 0;
}

.guest-options-modify .open-temp-form.edit-button {
    display: none;
}

.guest-options-modify .temp-form.show-selected~.open-temp-form {
    width: 20%;
}

.guest-options-modify .open-temp-form:hover {
    border: none;
    outline: none;
}

.guest-options-modify .open-temp-form.text-button {
    display: none;
}

.guest-options-modify .open-temp-form .plus-button,
.guest-options-modify .open-temp-form .edit-button {
    display: inline-block;
    height: 24px;
    width: 24px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.guest-options-modify .show-selected .open-temp-form {}

.guest-options-modify .known-trav .show-selected~.open-temp-form,
.guest-options-modify .freq-flyer .show-selected~.open-temp-form {
    right: 30px;
}

.action-icon.pencil {
    width: 24px;
    height: 24px;
    display: block;
}

.action-icon.plus {
    width: 24px;
    height: 24px;
    display: block;
    border: 2px solid #0055a0;
    border-radius: 100%;
    color: #0055a0;
    padding: 1px;
}

/*.guest-options-modify .open-temp-form .plus-button {
    background: url('/images/airbook/manage/ezair-manage-add-icon.svg') 0 0 no-repeat;
}
.guest-options-modify .open-temp-form .plus-button:hover,
.guest-options-modify .open-temp-form:focus .plus-button {
    background: url('/images/airbook/manage/ezair-manage-add-hover-icon.svg') 0 0 no-repeat;
}*/
.guest-options-modify .open-temp-form .edit-button {
    background: url('/images/airbook/manage/ezair-manage-edit-icon.svg') 0 0 no-repeat;
}

.guest-options-modify .open-temp-form .edit-button:hover,
.guest-options-modify .open-temp-form:focus .edit-button {
    background: url('/images/airbook/manage/ezair-manage-edit-hover-icon.svg') 0 0 no-repeat;
}

.guest-options-modify .open-temp-form .plus-button svg {
    height: 18px;
    width: 18px;
    line-height: 18px;
    vertical-align: unset;
}

.guest-options-modify .open-temp-form:hover .action-icon.plus,
.guest-options-modify .open-temp-form:focus .action-icon.plus {
    background: #0055a0;
    color: #FFF;
}

.guest-options-modify .special-services .show-input {
    padding: 0 25px;
}

.meal-requests-display {
    width: 100%;
    text-align: center;
    padding-right: 40px;
}

.guest-options-modify .temp-form .show-selected .ez-field-redress,
.guest-options-modify .temp-form .show-selected .ez-field-traveler {
    text-align: center;
    width: 90px;
    float: none;
    margin: 0 auto;
}

.action-icon.print-lw {
    background: url('/images/airbook/manage/ezair-manage-print-icon.svg') 0 0 no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
}

.open-temp-form:focus .pencil,
.action-icon.pencil:hover {}

.open-temp-form:active .action-icon.pencil:hover {}

.open-temp-form:focus .plus,
.action-icon.plus:hover {}

.open-temp-form:active .action-icon.plus:hover {}

.guest-options-modify .square {
    overflow: hidden;
}

.guest-options-modify .square .open-temp-form {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    z-index: 9999;
}

.guest-options-modify .open-temp-form .edit-button {
    display: none;
}

.guest-options-modify .show-selected input[type="text"] {
    pointer-events: none;
}

.guest-options-modify .show-selected~.open-temp-form {
    height: 50%;
}

.guest-options-modify .show-selected~.open-temp-form .plus-button {
    display: none;
}

.guest-options-modify .show-selected~.open-temp-form .edit-button {
    display: block;
}

.guest-options-modify #edit-pax-name-button {
    width: 25px;
    height: 25px;
    display: block;
}

.guest-options-modify .square .airline-icon {
    float: left;
    margin-right: 4px;
}

.guest-options-modify .temp-form input {
    border: none;
}

.guest-options-modify .temp-form input,
.guest-options-modify .temp-form div {
    font-size: 13px;
}

.guest-options-modify .temp-form.allSet .plus-button {
    display: none;
}

.book-additional-guests li {
    list-style: none;
}

.guest-options-lightbox .mfp-content #popupContent>div>h3 {
    margin: 0 0 1.9em;
    text-align: center;
    font-weight: 400;
    letter-spacing: normal;
    font-family: var(--red-hat-text-medium);
    font-size: 1.23076923076923em;
}

.guest-options-lightbox h3.passenger {
    letter-spacing: 0;
}

.guest-options-lightbox .content input,
.guest-options-lightbox .content select {
    width: 100%;
    display: block;
}

.guest-options-lightbox .content label {
    margin-bottom: 5px;
    display: inline-block;
}

.guest-options-lightbox .content label.mobile-header-swap {
    display: none;
}

.finished-submit .guest-options-validate-buttons,
.guest-options-lightbox .content,
.guest-options-lightbox .guest-options-validate-buttons {
    margin: 15px auto;
    text-align: center;
}

.finished-submit .guest-options-validate-buttons button,
.guest-options-lightbox .guest-options-validate-buttons button {
    padding: 7px;
    width: 120px;
    display: inline-block;
    margin: 0 5px;
}

.no-kids-warning button {
    margin: 0 5px;
}

.what-is-this {
    background: none;
    border: none;
    padding: 0;
}

.table-cell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.guest-options-lightbox div.show-input {
    display: none;
}

.guest-options-lightbox .square {
    padding: 10px 15px;
    border: 1px solid #CCC;
    margin: 5px 3px;
    width: 260px;
    display: inline-block;
    text-align: left;
    background: #e5f3fd;
    vertical-align: top;
}

.guest-options-lightbox .loading {
    display: block;
    text-align: center;
    margin: 26.5px 0px;
    position: relative;
}

.guest-options-lightbox .loading img {}

.guest-options-lightbox .open-temp-form {
    display: none;
}

.guest-options-lightbox .airline-icon {
    display: none;
}

.ff-head img {
    vertical-align: bottom;
}

.error-container {
    margin-top: 10px;
    overflow: hidden;
    padding-left: 20px;
    position: relative;
}

.error-container .save-error-msg img {
    position: absolute;
    top: 1px;
    left: 0;
    margin: 0;
}

.guest-flight-head {
    position: relative;
}

.guest-flight-head .guest-icons {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
}

.guest-flight-head .guest-icons button {
    background: none;
    margin-left: 10px;
    padding: 15px 25px;
    line-height: 27px;
    margin: 0;
    border: none;
}

.guest-flight-head .guest-icons button:focus {
    border: 5px auto -webkit-focus-ring-color;
}

.guest-flight-head .guest-icons button:hover,
.guest-flight-head .guest-icons button:focus {
    background: #07203C;
}

.guest-flight-head .guest-icons button span {
    margin-right: 3px;
}

.headerbar>.headerbar-wrapper {
    overflow: visible;
}

.headerbar-logo {
    margin: 10px 0 10px 10px;
}

.switch-guests {
    position: relative;
    display: inline-block;
}

.switch-guests .pc-icons.white-switch {
    background: url('/images/airbook/manage/ezair-manage-switch-guests-icon.svg') 0 0/100% no-repeat;
    position: relative;
    top: -2px;
    height: 19px;
    width: 19px;
}

.headerbar-btn>a {
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    font-size: 1em;
}

.product-PC .switch-guests .headerbar-btn-link,
.product-PC .switch-guests .switch-guests-dropdown {
    background: #0071BC !important;
}

.switch-guests .switch-guests-dropdown {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
}

body:not(.product-PC) .switch-guests .headerbar-btn-link,
body:not(.product-PC) .switch-guests .switch-guests-dropdown {
    background: #737373 !important;
}

body:not(.product-PC) .headerbar-btn:not(.switch-guests) .headerbar-btn-link {
    color: #737373;
}

.navbar .switch-guests-dropdown {
    display: none;
}

.headerbar-menu .switch-guests-dropdown {
    color: #fff;
    left: auto;
    right: 0;
    min-width: 14em;
    padding: 15px;
    position: absolute;
    text-align: left;
    top: -999em;
    z-index: 1000;
    font-family: var(--red-hat-text-medium);
    font-size: 1.07692307692308em;
}

.switch-guests .switch-guests-dropdown .switch-pnr-group+.switch-pnr-group {
    padding-top: 15px;
}

.switch-guests .switch-guests-dropdown a {
    color: #FFF;
    text-decoration: none;
}

.switch-guests .switch-guests-dropdown a:hover {
    text-decoration: underline;
}

.switch-guests .switch-guests-dropdown a,
.switch-guests .switch-guests-dropdown a:hover {
    text-decoration: none;
}

.switch-guests .switch-guests-dropdown a:hover .switch-pnr-guest {
    text-decoration: underline;
}

.switch-guests .switch-guests-dropdown a .switch-pnr-header {
    font-family: var(--red-hat-text-medium);
    letter-spacing: 1px;
    text-decoration: none !important;
}

.switch-guests .switch-guests-dropdown a .switch-pnr-guest {}

.switch-guests:hover .switch-guests-dropdown,
.switch-guests.expanded>.switch-guests-dropdown {
    top: 100%;
}

.ez-waiting-benefits-lock-checkmark::after,
.ez-waiting-benefits-low-prices::after,
.ez-waiting-benefits-booking::after,
.ez-waiting-benefits-protection::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: ".";
}

.ez-waiting-benefits-lock-checkmark::after {
    background-image: url(../../images/airbook/icon-booking-lock-checkmark.svg);
}

.lt-ie9 .ez-waiting-benefits-lock-checkmark::after {
    background-image: url(../../images/airbook/icon-booking-lock-checkmark.png);
}

.ez-waiting-benefits-low-prices::after {
    background-image: url(../../images/airbook/icon-low-prices.svg);
}

.lt-ie9 .ez-waiting-benefits-low-prices::after {
    background-image: url(../../images/airbook/icon-low-prices.png);
}

.ez-waiting-benefits-booking::after {
    background-image: url(../../images/airbook/icon-booking.svg);
}

.lt-ie9 .ez-waiting-benefits-booking::after {
    background-image: url(../../images/airbook/icon-booking.png);
}

.ez-waiting-benefits-protection::after {
    background-image: url(../../images/airbook/icon-arrival-protection.svg);
}

.lt-ie9 .ez-waiting-benefits-protection::after {
    background-image: url(../../images/airbook/icon-arrival-protection.png);
}

.ez-waiting-progress {
    background: url(../../images/airbook/loading-mobile-2x.gif) no-repeat center center;
    height: 64px;
    width: 100%;
    margin: 30px 0;
    background-size: 64px 64px;
}

.waiting-slider .ez-waiting-benefits-desktop-slides .ez-waiting-benefits-item:nth-child(1)::after {
    background-position: 70% 0;
}

.waiting-slider .ez-waiting-benefits-item:nth-child(1) div {
    padding-left: 25%;
}

.waiting-slider .ez-waiting-benefits-desktop-slides .ez-waiting-benefits-item:nth-child(2)::after {
    background-position: 50% 0;
}

.waiting-slider .ez-waiting-benefits-desktop-slides .ez-waiting-benefits-item:nth-child(3)::after {
    background-position: 25% 0;
}

.waiting-slider .ez-waiting-benefits-item:nth-child(3) div {
    padding-right: 25%;
}

.pc .ez-waiting-progress {
    margin: 10px 0 0;
    background-size: 32px 32px;
}

.product-CCL .ez-waiting-progress,
.product-CL .ez-waiting-progress {
    background: url(../../images/airbook/loading-blue-on-white.gif) no-repeat center center;
    background-size: 64px 64px;
}

.pc .ez-waiting-msg-text {
    color: white;
}

.tooltip {
    border: none;
    padding: 0;
    margin: 0;
    outline: 0;
    cursor: pointer;
}

.tooltip:focus {
    outline: -webkit-focus-ring-color auto 5px;
}

button.price-breakdown.tooltip:focus,
button.can-i-pay-now.tooltip:focus {
    outline: 2px dotted rgba(255, 255, 255, .8);
}

#content .tooltip:not(.ez-summary-sale-rbn) {
    background: none;
    color: #0054A0;
    text-decoration: underline;
}

#content .tooltip.plain,
#content .tooltip.pricing {
    text-decoration: none;
    color: inherit;
}

#content .itn-stop-airport.tooltip {
    text-decoration: none;
}

#content .sale-rbn-banner .tooltip {
    color: #FFF;
    text-decoration: none;
}

#content .itn-stop-airport.tooltip {
    text-decoration: none;
}

.tooltip-pricing-container {
    padding: 5px;
}

.tooltip-pricing-table {
    min-width: 220px;
    width: 100%;
}

.farecard-pricing-table.tooltip-pricing-table {
    min-width: 170px;
}

.tooltip-pricing-table div {
    padding: .5em 0;
}

/* Accordion */

.ez-disabled-accordion .rwd-accordion-content {
    display: block;
}

.ez-disabled-accordion .rwd-accordion-header-select,
.ez-disabled-accordion .rwd-accordion-close-btn {
    display: none !important;
}

.ez-disabled-accordion .rwd-accordion-header:hover {
    cursor: auto;
}

.gray-f1-bg>.accordion,
.gray-f1-bg>.rwd-accordion {
    border-top: none;
    margin-top: 0;
}

.rwd-accordion-wrapper {
    overflow: hidden;
}

.rwd-accordion-content {
    padding-bottom: 30px;
}

#ez-booked-summary-aside .print-btn .action-icon {
    background: url(/images/global/sprite_pc-icons.svg) no-repeat;
    display: inline-block;
    background-position: -153px -20px;
    width: 19px;
    height: 12px;
}

.no-svg .print-btn .action-icon {
    background: url(/images/global/sprite_pc-icons.png) no-repeat;
}

.ez-fare-chart.new table {
    width: 100%;
}

.ez-fare-chart.new th {
    text-align: center;
    border: 0;
}

.ez-fare-chart.new td {
    max-width: 18em;
    height: 9em;
}

.ez-fare-chart.new td.ez-darkblue-bg {
    padding: 10px;
}

.ez-fare-chart.new .ez-chart-checkmark {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
}

.ez-fare-chart.new .ez-chart-checkmark img {
    border-radius: 50%;
    box-sizing: initial;
    padding: 7px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    box-sizing: content-box;
}

@keyframes plane {
    0% {
        left: -3%;
    }

    100% {
        left: 90%;
    }
}

.ez-waiting-plane {
    animation-name: plane;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-delay: 1s;
    animation-timing-function: linear;
}

@keyframes bar {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

.ez-waiting-progress-green {
    animation-name: bar;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-delay: 1s;
    animation-timing-function: linear;
}

.pending-ticket {
    background: #FFCA56;
    display: inline-block;
    padding: 0px 8px;
    border-radius: 10px;
    line-height: 1.7em;
}

.issued-ticket {
    background: #B8E986;
    display: inline-block;
    padding: 0px 8px;
    border-radius: 10px;
    line-height: 1.7em;
}

.web-font .gotham-xn-large,
.gotham-xn-large {
    font-family: var(--red-hat-text-bold);
    letter-spacing: 0;
}

.guest-options-lightbox .content .apply-to-all {
    margin-top: 10px;
}

.guest-options-lightbox .content .apply-to-all label {
    margin-bottom: 0;
}

.guest-options-lightbox .content .apply-to-all.disabled label {
    color: #666;
}

.guest-options-lightbox .content .apply-to-all input {
    margin-bottom: 0;
    width: auto;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: 2px;
    float: left;
}

#popupContent .e-ticket-model .guest-options-row:not(.thead) .guest-options-cell {
    align-items: baseline;
    min-height: auto;
}

.view-e-ticket-depart-content .ticket-direction-R {
    display: none;
}

.view-e-ticket-return-content .ticket-direction-D {
    display: none;
}

.view-e-ticket-depart-content .guest-options-row:last-of-type,
.view-e-ticket-return-content .guest-options-row:last-of-type {
    margin-bottom: 30px;
}

.ticket-block {
    line-height: 30px;
}

.ticket-block .itn-carrier-logo {
    margin: 0;
    position: relative;
    top: 5px;
}

a[data-error-message*='cancel'],
a[data-error-message*='name'],
a[data-error-message*='issued'] {
    display: none;
}

.small-tooltip {
    font-size: 11px;
}

.show-on-mobile {
    display: none;
}

.showOnHighContrast {
    display: none;
}

.tnt-bar {
    border-radius: 5px;
    background-color: #F6C632;
    text-align: center;
    padding: 12px 10px;
}

.tnt-bar p {
    margin: 0;
}

.tnt-bar a {
    color: #000;
}

.huge-currency {
    font-size: 3.75em;
    top: -22px;
    position: relative;
}

.huge-price {
    font-size: 6.25em;
}

.huge-due-now {
    display: inline-block;
    line-height: 35px;
}

.auto-add-air-container {
    background: #e6e6e6;
    position: relative;
}

.recommended-flights {
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid #767676;
    background: #FFF;
}

.recommended-top {
    border-bottom: 1px solid #767676;
    line-height: 16px;
    transition: all .4s linear;
}

.recommended-top:hover {
    background: #d2e1ee;
}

.recommended-top .open-hide {
    opacity: 1;
    transition: all .4s linear;
}

.recommended-top.recommended-open {
    background: #0054a0;
}

.recommended-top.recommended-open .open-hide {
    opacity: 0;
    pointer-events: none;
}

.recommended-top>.row {
    height: 55px;
}

#ez-autoair-new-top .recommended-top .bump {
    margin-top: 8px;
}

.recommended-top .recommended-title {
    margin: 5px 0 2px;
    transition: all .4s linear;
}

.recommended-top.recommended-open .recommended-title {
    color: #FFF;
    margin-top: 15px;
}

.recommended-top .recommended-title+div {
    line-height: 1.6;
}

.hide-text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.recommended-top .itn-carrier-logo {
    height: 16px;
}

.auto-add-air-container .recommended-flights .recommended-top .plane {
    background: url('/images/airbook/sprite_air-icons.svg');
}

.recommended-stops {
    display: none;
}

.recommended-time span {
    display: none;
}

.aaa-arrow {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    margin-top: 3px;
    background: none;
    border: none;
    cursor: pointer;
}

.aaa-arrow::after,
.aaa-arrow::before {
    position: absolute;
    height: 5%;
    width: 25%;
    background: #000;
    top: 50%;
    content: '';
}

.aaa-arrow::after {
    left: 29%;
}

.aaa-arrow::before {
    right: 29%;
}

.aaa-arrow[aria-expanded="false"]::after,
.aaa-arrow[aria-expanded="true"]::before {
    transform: rotate(45deg);
}

.aaa-arrow[aria-expanded="true"]::after,
.aaa-arrow[aria-expanded="false"]::before {
    transform: rotate(-45deg);
}

.recommended-top.recommended-open .aaa-arrow::after,
.recommended-top.recommended-open .aaa-arrow::before {
    background: #FFF;
}

.recommended-top:hover .aaa-arrow,
.recommended-top .aaa-arrow:hover,
.recommended-top .aaa-arrow:focus {
    background: #0054a0;
}

.recommended-top:hover .aaa-arrow::after,
.recommended-top:hover .aaa-arrow::before,
.recommended-top .aaa-arrow:hover::after,
.recommended-top .aaa-arrow:hover::before,
.recommended-top .aaa-arrow:focus::after,
.recommended-top .aaa-arrow:focus::before {
    background: #FFF;
}

.recommended-bottom {
    border: solid #767676;
    border-width: 0 0 1px 0;
    display: none;
}

.recommended-flights .recommended-flight:last-of-type .recommended-top {
    border-bottom: none;
}

.recommended-flights .recommended-flight:last-of-type .recommended-bottom {
    border-width: 1px 0 0 0;
}

.recommended-bottom .ez-itn-full-wrapper {
    position: relative;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.text-align-justify {
    text-align: justify;
}

.name-change-number {
    color: black;
    font-weight: 700;
    font-size: 21px;
}

.bold {
    font-weight: 600;
}

.hazard {
    font-weight: 700;
    color: #ff6600;
    font-size: 40px;
    position: relative;
    top: 8px;
}

.filter-price {
    color: black;
}

.reco-farecard-both-tablet {
    left: auto;
    float: left;
    margin-left: 1%;
    padding: 0;
    vertical-align: top;
    width: 49%;
}

.reco-sale-price {
    color: #bd0000;
}

.reco-sale-banner {
    background-color: #bd0000;
    color: #fff;
    position: relative;
    font-size: 10px;
    line-height: 14px;
    padding: 0px;
    width: 45px;
    text-align: center;
}

#site-feedback-qualtrics-btn {
    background-color: #0054a0;
    color: white;
    position: fixed;
    bottom: 10px;
    right: 15px;
    border-radius: 10px;
    border-color: #0054a0;
    padding: 5px 15px 5px 15px;
    text-align: center;
    z-index: 999999;

}

.card-easy-seperator {
    height: .4125em;
    background: white;
    width: calc(100% + 45px);
    position: relative;
    right: 30px;
}

.card-easy-fields-container {
    padding: 0px;
    display: inline-block;
    text-align: left;
}

.full-width-field-250 {
    width: 100%;
    max-width: 250px;
}

.cez-required {
    color: #bd0000;
    font-size: 1.2307em;
    font-weight: 700;
    top: 0;
    right: .076923em;
}

.cez-80-width-field {
    width: 80%;
    max-width: 250px;
}

.scale-radio-50 {
    transform: scale(1.5);
}

/** Name Change Form */
.current-name-highlight {
    background-color: #e5e2e2;
    margin: 3px;
    padding: 5px;
}

#name-change-form-steps .nm-form-step {
    display: none;
}

#name-change-form-steps .active.nm-form-step {
    display: inline-block;
    margin: 10px;
}

#name-change-form-steps .name-opt-list {
    width: auto;
    max-width: 48%;
    margin: 10px auto 35px auto;
}

#name-change-form-steps .nm-form-step section {
    margin-top: 22px;
}

#nameChangeForm fieldset .form-field label:first-child {
    margin-left: 10px;
}

#name-change-rules-step legend:not(.text-align-left) {
    margin: 0 auto;
}

.nmn-chkbox-wrapper input.no-middle-name {
    width: 16px;
    display: inline-block;
    vertical-align: middle;
}

.nmn-chkbox-wrapper label {
    padding-top: 32px;
    padding-left: 10px;
    width: 145px;
}

.mn-input-wrapper {
    min-width: 110px;
}

.overflow-hidden {
    overflow: hidden;
}

.float-left {
    float: left;
}

/** View Flight Class Restrictions (Basic Economy) */
.ez-flight-class-type {
    margin: auto;
}

.ez-class-restictions .cabin-class-details {
    width: auto;
    margin: 0 auto;
    max-width: 85%;
    margin-top: 25px;
    margin-bottom: 25px;
}

.ez-class-restictions .exclamation-icon {
    margin-right: 5px;
    display: inline-flex;
    background-size: 645px;
    background-position: -102px -42px;
}

.exclamation-icon {
    width: 15px;
    height: 15px;
    display: block;
    background-repeat: no-repeat;
    background-image: url(/images/global/sprite_pc-icons.svg);
}

.class-restrictions-list {
    height: auto;
    overflow-x: auto;
    max-height: 225px;
}

.cc-list-header {
    min-height: 45px;
    padding: 10px 0 0 15px;
    background-color: #f2f2f2;
}

/** EZ Marketing Cards */
.ez-card-wrapper {
    min-height: 260px;
}

.ez-card-content {
    min-height: 245px;
    display: table-cell;
    vertical-align: middle;
}

.ez-icon.ez-bg-icon {
    height: 70px;
    display: block;
    margin: 0 auto;
    max-width: 100%;
}

.ez-info-fit-flights .ez-icon {
    background-position: -292px -12px;
    background-size: 370px 105px;
    width: 80px;
}

.ez-info-low-fares-flights .ez-icon {
    background-position: 10px -22px;
    background-size: 320px 115px;
    width: 55px;
}

.ez-info-flex-change-flights .ez-icon {
    background-position: -49px -15px;
    background-size: 315px 105px;
    width: 85px;
}

.ez-info-protection-flights .ez-icon {
    background-position: -159px -15px;
    background-size: 335px 105px;
    width: 85px;
}

.floating-cards .ez-card-wrapper {
    background: #fff;
    border-bottom: 1px solid #ccc;
    width: 100%;
}

.learn-more-btn {
    padding-right: 5%;
}

/** Session timeout popup */
.session-counter {
    color: #c82423;
    font-size: 2em;
}

.session-timeout-error {
    max-width: 300px;
    text-align: center;
    margin: auto;
}

.session-time {
    color: #c82423;
    font-size: 1.5em;
}

.blue-line-decorator {
    background-color: #8cabc7;
    height: 15px;
    width: 100%;
    margin-top: 10px;
}

.session-hazard {
    width: 40px;
    height: 35px;
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url(/images/global/sprite_pc-icons.svg);
    background-size: 865px;
    background-position: 0px -227px;
}

.orange {
    color: #BE4524;
}


@media screen and (min-width: 640px) {
    .floating-cards .ez-card-wrapper {
        border: 1px solid #ccc;
    }
}

@media screen and (-ms-high-contrast: black-on-white) {
    .showOnHighContrast {
        display: block;
    }

    .ez-waiting-progress {
        border: 1px solid #FFF;
    }

    .ez-waiting-progress-green {
        border: 4px solid #FFF;
    }
}

@media screen and (-ms-high-contrast: white-on-black) {
    .showOnHighContrast {
        display: block;
    }

    .ez-waiting-progress {
        border: 1px solid #FFF;
    }

    .ez-waiting-progress-green {
        border: 4px solid #FFF;
    }
}

/*===============================================================================*/


@media (min-width: 40em) {

    .margin-left-10 {
        margin-left: 10px;
    }

    .headerbar-btn>a {
        padding-top: 15.5px;
        padding-bottom: 15.5px;
        font-size: 1.07692307692308em;
    }

    .ezair-header-inner {
        max-width: 990px;
        margin: 0 auto;
        height: 52px;
    }

    .ez-header-logo {
        height: 32px;
        width: 251px;
    }

    .product-PC .ez-header-logo {
        background: url('/images/global/princess-logo_h.svg') top left no-repeat;
    }

    .product-HA .ez-header-logo {
        background-image: url('/images/airbook/header/hal-logo.png');
    }

    .product-PA .ez-header-logo {
        background-image: url('/images/airbook/PA_PA_logo.png');
    }

    .product-CU .ez-header-logo {
        background-image: url('/images/airbook/header/cunard-logo.svg');
    }

    .product-CCL .ez-header-logo,
    .product-CL .ez-header-logo {
        background-image: url('/images/airbook/header/carnival-logo.png');
    }

    .product-SB .ez-header-logo {
        background-image: url('/images/airbook/header/seabourn-logo.png');
    }

    .ezair #princess-header .headerbar-menu,
    .ezair-classic #princess-header .headerbar-menu {
        display: block;
    }

    #ez-search-filters .form-field {
        width: 13em;
    }

    #ez-search-filters .checkbox {
        width: 13em;
    }

    #ez-ship-bg {
        background-position: 0px -144px;
    }

    .ezair.ez-iframed #tab-container {
        padding-left: 5px;
        padding-right: 5px;
    }

    .ez-preview-availability-chk {
        right: 0;
        top: 37%;
    }

    .ez-preview-availability-chk.error-msg {
        right: 0;
        top: 20%;
    }

    .ez-rslt-fares-explained {
        padding-top: 0;
    }

    #ez-rslt-sort {
        display: inline-block;
    }

    #ez-rslt-sort-select {
        padding-top: 3px;
    }

    .itn-route-limited .itn-route {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, .3);
    }

    #ez-autoair .itn-container-wrapper,
    .ez-preview-itns .itn-container-wrapper {
        background: transparent;
        padding-bottom: 0;
        box-shadow: none;
        max-width: none;
    }

    .ez-preview-itns .itn-container-wrapper {
        margin-top: 20px;
    }

    .ez-preview-itns .itn-container-wrapper+.itn-container-wrapper {
        border-top: 1px solid #d0d1d2;
        margin-top: 40px;
        padding-top: 30px;
    }

    .ez-preview-itns .itn-route,
    #ez-autoair-new-top .itn-route,
    #ez-autoair-new-bottom .itn-route,
    #ez-autoair .itn-route {
        position: relative;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, .3);
    }

    .ez-preview-itns .ez-fare-card,
    #ez-autoair .ez-fare-card,
    #ez-autoair-new-top .ez-fare-card,
    #ez-autoair-new-bottom .ez-fare-card {
        left: auto;
        float: left;
        margin-left: 1%;
        padding: 0;
        width: 165px;
    }

    #ez-autoair-new-top .ez-fare-card,
    #ez-autoair .ez-fare-card {
        width: 19.7%;
    }

    #ez-autoair-new-top .ez-fare-card,
    #ez-autoair-new-bottom .ez-fare-card {
        width: 34%;
    }

    .ez-preview-itns .itn-footer,
    #ez-autoair-new-top .itn-footer,
    #ez-autoair-new-bottom .itn-footer,
    #ez-autoair .itn-footer {
        position: relative;
        bottom: auto;
        background-color: transparent;
    }

    .ez-itin-limited-wrapper.itn-container-wrapper {
        background: transparent;
        box-shadow: none;
    }

    .fwf-guest-name {
        position: relative;
        top: auto;
    }

    .ez-preview-itns .ez-itn-full-wrapper {
        margin-top: 20px;
    }

    .ribbon-banner .ez-search-sale-title {
        display: inline-block;
    }

    #ez-dpt-summary .ez-itin-data[data-stops="3"] .itn-layover {
        padding: 14px 3% 0;
    }

    #ez-dpt-summary .ez-itin-data[data-stops="4"] .itn-layover,
    #ez-dpt-summary .ez-itin-data[data-stops="5"] .itn-layover,
    #ez-dpt-summary .ez-itin-data[data-stops="6"] .itn-layover {
        padding: 14px 1.2% 0;
    }

    .ez-rslt-currency-type {
        top: 22px;
        right: 22px;
    }

    .ezair .article-container {
        border-radius: 0 0 10px 10px;
    }

    .ezair .article-container-secondary {
        border-radius: 10px;
    }

    .ezair-search #content {
        padding: 40px 0;
    }

    #content.has-originating-system-link {
        padding-top: 60px;
    }

    .mfp-content #popupContent {
        padding: 0 30px 30px;
    }

    .ez-validation-messages .mfp-content #popupContent {
        padding: 30px;
    }

    .ez-big-button {
        padding: 0.3em 1.5em;
        min-width: 170px;
    }

    #ez-search-sale-banner {
        border-bottom: 5px solid #a09f9f;
        position: absolute;
        top: 0;
        z-index: 96;
    }

    #ez-search-sale-banner.ez-search-sale-banner-pb {
        border-bottom: 0;
        position: relative;
    }

    .ribbon-banner .ez-search-sale-title {
        display: none;
    }

    #ez-search-sale-banner.ribbon-banner {
        float: none;
        width: auto;
        min-width: 0;
        height: 34px;
        left: 24em;
        top: 13px;
        padding: 0px 10px;
        border-bottom: 0;
    }

    #ez-search-sale-banner.ribbon-banner:after {
        content: "";
        position: absolute;
        display: block;
        bottom: 0;
        border: 17px solid #bd0000;
        z-index: -1;
        left: 100%;
        border-left-width: 5px;
        border-right-width: 10px;
        border-right-color: transparent;
    }

    .ribbon-banner .ez-search-sale-title {
        border: 0;
    }

    .ez-search-sale-title {
        display: inline-block;
    }

    .product-PC #ezair-header-top {
        border-bottom: 1px solid #82a6c7;
        margin-bottom: 1px;
    }

    .ezair-error #ezair-header-top {
        margin-bottom: 0;
    }

    #ezair-header-bottom {
        height: 39px;
        background: url(/images/global/sprite_header-nav-footer.png) repeat-x 0 -35px #fcfcfc;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fcfcfc), color-stop(50%, #f7f7f7), color-stop(100%, #dcdcdc));
        background: -webkit-linear-gradient(top, #fcfcfc 0, #f7f7f7 50%, #dcdcdc 100%);
        background: -moz-linear-gradient(top, #fcfcfc 0, #f7f7f7 50%, #dcdcdc 100%);
        background: -ms-linear-gradient(top, #fcfcfc 0, #f7f7f7 50%, #dcdcdc 100%);
        background: -o-linear-gradient(top, #fcfcfc 0, #f7f7f7 50%, #dcdcdc 100%);
        border-bottom: 1px solid #9b9b9b;
    }

    .article-header .ez-back-to-host {
        position: absolute;
        top: -35px;
        right: 0;
    }

    .ezair-search .article-header .ez-back-to-host {
        top: -38px;
    }

    .ez-back-to-host .ez-back-to-host-link {
        color: #FFF;
    }

    .ezair .full-tab {
        padding-left: 15px;
        padding-top: 0;
    }

    #ez-autoair .full-tab {
        padding-left: 0;
    }

    .article-header .droptab-container {
        margin-bottom: -15px;
    }

    .ez-search-booked {
        border-top: 0;
    }

    #ez-search-filters {
        background-color: #e5f3fd;
        margin: 10px 0;
        padding-bottom: 0;
        box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, .3), 3px 2px 4px -2px rgba(0, 0, 0, .3), -3px 2px 3px -2px rgba(0, 0, 0, .3);
    }

    #ez-search-filters legend {
        color: #333333;
    }

    #ez-search-filters>span:first-of-type {
        padding-left: 0;
    }

    /* hackier version of above for ie8 */
    .lt-ie9 #ez-search-filters legend+.form-field {
        padding-left: 0;
    }

    #ez-search-filters>span.hidden+span {
        padding-left: 0;
    }

    #ez-search-filters .form-field {
        width: 15.5em;
    }

    #ez-search-filters .checkbox {
        width: 15.5em;
        padding-left: 30px;
    }

    #ez-search-filters .form-field select {
        width: 100%;
        min-width: 0;
    }

    .form-field.ez-horizontal {
        padding: 10px 15px 10px 15px;
        display: inline-block;
    }

    .form-field.ez-horizontal.col-sm-pad-0-left {
        padding-left: 0;
    }

    .form-field.ez-horizontal label {
        color: #333;
        display: inline-block;
        padding: 0;
    }

    .ez-horizontal label {
        width: auto;
    }

    .form-checkbox-wrapper {
        margin-left: 0;
    }

    #ez-search-filters .checkbox .form-checkbox-wrapper input {
        float: left;
        margin-left: -1.5em;
    }

    #ez-search-depart {
        width: 48%;
    }

    #ez-search-return {
        width: 48%;
        left: 4%;
        margin-top: 0;
    }

    #ez-search-dir-bg:before {
        content: "";
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #e5f3fd;
        z-index: -1;
        display: block;
    }

    #ez-search-depart .ez-search-dir-header {
        border-radius: 0 6px 0 0;
    }

    #ez-search-return .ez-search-dir-header {
        border-radius: 6px 0 0 0;
    }

    .ez-search-dir-header {
        width: 90%;
    }

    #ez-search-return .ez-search-dir-header {
        left: 10.1%;
    }

    #ez-search-return .ez-linked-direction-message {
        padding-bottom: 10px;
        font-size: 12px;
        font-weight: bold;
    }

    #ez-search-depart .ez-linked-direction-message {
        padding-bottom: 10px;
        font-size: 12px;
        font-weight: bold;
    }

    .ez-search-dir-container.deactive {
        display: block;
    }

    .ez-search-dir-container .inline-field {
        padding-right: 15px;
    }

    .ez-search-booked {
        border-left: 1px solid #d0d1d2;
        border-top: 0;
    }

    .ezair-manage-flights .ez-search-booked {
        border: 0;
    }

    .ez-info {
        border: 1px solid #d0d1d2;
    }

    .ez-info-item {
        text-align: center;
    }

    /* Search Results */

    .ez-header-links .ez-back-to-host-link {
        position: absolute;
        right: 10px;
        top: -35px;
        color: #FFF;
    }

    body[data-view="select-return"] .ez-header-links .ez-back-to-host-link {
        display: none;
    }

    body[data-view="select-return"] #ez-dpt-summary .ez-back-to-host-link {
        /*display: block;*/
        position: absolute;
        right: 15px;
        top: -25px;
        color: #FFF;
    }

    .fare-table-wrapper {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, .3);
    }

    .ezair-results .header-link {
        right: 20px;
        top: 10px;
    }

    .ez-fare-chart th span {
        padding: 4px 20px;
    }

    .ez-fare-chart th {
        width: 10em;
    }

    .ez-fare-chart td {
        height: 5em;
        padding: 20px;
    }

    #ez-rslt-sidebar {
        display: block;
    }

    #ez-rslt-filters {
        font-size: 1em !important;
    }

    .ez-rslt-topbar-wrapper {
        background-color: white;
    }

    .ez-fltr-mobile-header {
        background-color: #007ba3;
        border-bottom: 0;
        font-size: 1em !important;
        height: 28px;
    }

    .ez-rslt-fltr-header {
        display: block;
    }

    .ez-rslt-fltr-menu {
        display: block;
    }

    .ez-rslt-mobile-filter {
        display: none;
    }

    #ez-fltr-mobile-open,
    #ez-fltr-apply-wrapper {
        display: none;
    }

    .ez-fltr-mobile-header .arrow-toggle {
        display: none;
    }

    #ez-rslt-fltr-mobile-header {
        display: none;
    }

    #ez-rslt-filters .ez-rslt-fltr-menu input[type='checkbox'],
    #ez-rslt-filters .ez-rslt-fltr-menu input[type='radio'] {
        margin-left: -1.5em;
    }

    #ez-rslt-filters .checkbox label {
        padding-left: 3px;
    }

    #ez-rslt-fltr-sortby label {
        padding-left: 5px;
    }

    #ez-rslt-topbar .ez-help-info {
        display: none;
    }

    .ez-rslt-found {
        text-align: left;
    }

    #ez-rslt-sort-select {
        display: block;
    }

    .ez-rslt-fares-explained {
        text-align: right;
    }

    .itn-route {
        position: static;
    }

    .itn-stop-container {
        padding-top: 0;
    }

    .itn-origin {
        text-align: right;
        padding-left: 5px;
        padding-right: 15px;
        width: 25%;
        position: relative;
    }

    .ezair .itn-layover {
        line-height: 14px;
        position: relative;
        top: auto;
        padding: 0 3%;
    }

    .ez-itin-data[data-stops="4"] .itn-layover,
    .ez-itin-data[data-stops="5"] .itn-layover,
    .ez-itin-data[data-stops="6"] .itn-layover {
        padding: 0 1.4%;
    }

    #ez-dpt-summary .ez-itin-data[data-stops="3"] .itn-layover {
        padding: 14px 1% 0;
    }

    #ez-dpt-summary .ez-itin-data[data-stops="4"] .itn-layover,
    #ez-dpt-summary .ez-itin-data[data-stops="5"] .itn-layover,
    #ez-dpt-summary .ez-itin-data[data-stops="6"] .itn-layover {
        padding: 14px 0 0;
    }

    .itn-stop-time {
        display: block;
    }

    #ez-dpt-summary .ez-itin-data[data-stops="3"] .itn-stop-time,
    #ez-dpt-summary .ez-itin-data[data-stops="4"] .itn-stop-time,
    #ez-dpt-summary .ez-itin-data[data-stops="5"] .itn-stop-time,
    #ez-dpt-summary .ez-itin-data[data-stops="6"] .itn-stop-time {
        display: none;
    }

    .itn-stop-airport {
        font-size: 1em;
    }

    .ezair .itn-final {
        text-align: left;
        padding-right: 5px;
        padding-left: 15px;
        width: 25%;
        position: relative;
        top: auto;
        right: auto;
    }

    .itn-stop-container>.row {
        min-height: 0;
    }

    .ezair .itn-line {
        width: 50%;
        left: 25%;
        top: 10px;
    }

    .itn-total-duration {
        display: block;
    }

    .ez-mobile-menu {
        background-color: #cedfec;
        border-radius: 3px;
        color: #333;
    }

    #show-flight-changing {
        background-color: #ebebeb;
    }

    #show-flight-changing .itn-row {
        background-color: #ebebeb;
        min-height: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }

    #show-flight-changing .itn-carrier-logo {
        float: right;
        padding-right: 2px;
    }

    #show-flight-changing .itn-stop-img {
        background-color: #ebebeb;
    }

    #show-flight-changing .itn-oper-details {
        display: none;
    }

    #show-flight-changing .ez-dpt-summary-cell.previous-flight-heading {
        padding-top: 30px;
    }

    .itn-container {
        display: contents;
    }

    .itn-dtl-carrier-logo {
        width: 20%;
    }

    .itn-dtl-carrier-info {
        width: 75%;
    }

    .itn-dtl-row {
        border-left: 1px solid #b2b2b2;
        border-right: 1px solid #b2b2b2;
    }

    #ez-dpt-summary .itn-route-wrapper {
        margin-top: -12px;
        border: 0;
    }

    .ez-dpt-summary-cell {
        text-align: left;
    }

    .ezair-search .droptab-container-disabled {
        display: block;
    }

    .droptab-disabled {
        padding-left: 15px !important;
        padding-bottom: 3px !important;
        margin-left: 0;
        margin-right: 0;
    }

    .droptab-disabled li {
        width: auto;
        text-align: left;
    }

    .droptab-disabled li a {
        padding: 10px 20px;
    }

    .droptab-disabled li.active:after {
        display: block;
    }

    .ez-details-change-link {
        position: absolute;
        top: -25px;
        right: 0;
    }

    .ez-preview-itns .ez-fare-card {
        left: auto;
    }

    .ez-summary-sale-rbn {
        margin-left: -38px;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, .6);
    }

    .ez-summary-sale-rbn:after {
        display: block;
    }

    .ez-summary-savings-text {
        padding-left: 20px;
    }

    .fwf-reset-guests-container {
        border: 0;
        padding-top: 0;
        text-align: left;
    }

    .ez-rslt-fares-explained {
        padding-top: 15px;
    }

    .sm-block {
        display: block;
    }

    .sm-inline-block {
        display: inline-block;
    }

    .sm-align-right {
        text-align: right;
    }

    .sm-align-left {
        text-align: left;
    }

    .sm-absolute {
        position: absolute;
    }

    #ez-autoair.new-design {
        display: none !important;
    }

    #ez-autoair-new-top,
    #ez-autoair-new-bottom {
        display: block;
    }
}

@media (min-width: 62em) {

    .headerbar-btn>a {
        padding-top: 15.5px;
        padding-bottom: 15.5px;
    }

    #ez-rslt-return .ez-fare-card,
    .ez-filtered-flexible .ez-fare-card,
    .ez-filtered-restricted .ez-fare-card,
    .itn-solo-farecard .ez-fare-card {
        left: 80%;
    }

    .lg-absolute {
        position: absolute;
    }

    .lg-inline {
        display: inline;
    }

    #ez-search-filters .form-field {
        width: 15.5em;
    }

    #ez-search-filters .checkbox {
        width: 13em;
    }

    .ez-saved-flight .ez-rslt-currency-type {
        top: 30px;
    }

    .ez-preview-itns .ez-itn-full-wrapper .itn-route {
        background: transparent;
        padding-bottom: 0;
        box-shadow: none;
        max-width: none;
        position: static;
    }

    .ez-preview-itns .ez-itn-full-wrapper {
        position: relative;
    }

    .ez-preview-availability-chk {
        right: 0;
        top: 45%;
    }

    .itn-full {
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .itn-full .itn-row:last-child:nth-of-type(even) {
        border-bottom: 0;
    }

    .ez-preview-itns .itn-footer-terms {
        padding-left: 35px;
    }

    .itn-route,
    .ez-filtered-flexible.ez-filtered-restricted .itn-route {
        width: 60.11111111111%;
        border-radius: 3px;
    }

    #ez-rslt-return .itn-route,
    .ez-filtered-restricted .itn-route,
    .ez-filtered-flexible .itn-route {
        width: 79.305556%;
    }

    .ezair .itn-route-wrapper {
        padding-top: 10px;
    }

    .ez-itin-limited-wrapper .itn-row:last-child {
        padding-bottom: 20px;
    }

    .ezair .itn-route-wrapper,
    .ezair.autoair-flow .ez-multicity-search .itn-route-wrapper,
    .ezair.autoair-flow .ez-multicity-search .itn-route-wrapper:before {
        min-height: 145px;
    }

    .itn-route-wrapper:before {
        min-height: 0 !important;
    }

    .lt-ie9 .ezair .itn-route-wrapper,
    .lt-ie9 .ezair .itn-route-wrapper:before {
        min-height: 154px;
    }

    .ezair.autoair-flow .itn-route-wrapper,
    .ezair.autoair-flow .itn-route-wrapper:before,
    .itn-container-wrapper[data-flex-discount='true'] .itn-route-wrapper,
    .itn-container-wrapper[data-rstr-discount='true'] .itn-route-wrapper,
    .itn-container-wrapper[data-flex-discount='true'] .itn-route-wrapper:before,
    .itn-container-wrapper[data-rstr-discount='true'] .itn-route-wrapper:before,
    .ez-itn-full-wrapper[data-flex-discount='true'] .itn-route-wrapper,
    .ez-itn-full-wrapper[data-rstr-discount='true'] .itn-route-wrapper,
    .ez-itn-full-wrapper[data-flex-discount='true'] .itn-route-wrapper:before,
    .ez-itn-full-wrapper[data-rstr-discount='true'] .itn-route-wrapper:before {
        min-height: 210px;
    }

    .ezair.autoair-flow .itn-container-wrapper[data-rstr-discount='true'] .itn-route-wrapper,
    .ezair.autoair-flow .itn-container-wrapper[data-rstr-discount='true'] .itn-route-wrapper:before,
    .ezair.autoair-flow .itn-container-wrapper[data-flex-discount='true'] .itn-route-wrapper,
    .ezair.autoair-flow .itn-container-wrapper[data-flex-discount='true'] .itn-route-wrapper:before {
        min-height: 200px;
    }

    #ez-dpt-summary .itn-route-wrapper {
        min-height: 0;
        background-color: #ebebeb;
        padding-top: 0;
    }

    #ez-dpt-summary .itn-route-wrapper:before {
        display: none;
    }

    .itn-row {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        font-size: 1em;
    }

    .itn-oper-details+.itn-cabin-class-msg {
        padding-top: 0 !important;
    }

    .ez-fare-card {
        width: 18.9%;
        margin-left: 1%;
        padding: 0;
        /* new styles */
        position: absolute;
        background-color: white;
        top: 0;
        bottom: 0;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, .3);
        border-radius: 3px;
    }

    .ez-multicity-search .ez-fare-card,
    .itn-bothways .ez-fare-card {
        box-shadow: none;
        background: transparent;
    }

    .ez-fare-card-flex,
    #ez-autoair-new-top .ez-fare-card-flex,
    #ez-autoair-new-bottom .ez-fare-card-flex,
    #ez-autoair .ez-fare-card-flex,
    #ez-autoair-new-top .itn-solo-farecard .ez-fare-card,
    #ez-autoair-new-bottom .itn-solo-farecard .ez-fare-card,
    #ez-autoair .itn-solo-farecard .ez-fare-card,
    .ez-preview-itns .ez-fare-card-flex,
    .ez-preview-itns .itn-solo-farecard .ez-fare-card-restricted {
        left: 60.15%;
    }

    .ez-fare-card-restricted,
    #ez-autoair-new-top .ez-fare-card-restricted,
    #ez-autoair-new-bottom .ez-fare-card-restricted,
    #ez-autoair .ez-fare-card-restricted {
        left: 80%;
    }

    .ez-preview-itns .ez-fare-card-restricted {
        left: 79%;
    }

    .itn-container-wrapper-modify .itn-route {
        width: 79%;
    }

    .itn-container-wrapper-modify .ez-fare-card {
        left: 80%;
    }

    .ezair .ez-card-cell {
        box-shadow: none;
    }

    .ezair .ez-multicity-search .ez-card-cell,
    .ezair .itn-bothways .ez-card-cell {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, .3);
        height: auto;
    }

    .itn-container-wrapper {
        background: transparent;
        padding-bottom: 0;
        box-shadow: none;
        z-index: 1;
    }

    .itn-route:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: #e5f3fd;
        /* .light-blue-bg */
        width: inherit;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, .3);
        border-radius: 3px;
    }

    .recommended-flight .itn-route:before {
        z-index: 0;
        background: none;
    }

    .itn-route-limited .itn-route:before {
        display: none;
    }

    .itn-footer {
        position: relative;
        bottom: auto;
        background-color: transparent;

    }

    #ez-autoair-new-top .itn-route,
    #ez-autoair-new-bottom .itn-route,
    #ez-autoair .itn-route {
        position: static;
        box-shadow: none;
        float: left;
    }

    #ez-autoair-new-top .itn-layover,
    #ez-autoair-new-bottom .itn-layover,
    #ez-autoair .itn-layover {
        padding: 0 1.5%;
    }

    #ez-autoair-new-top .itn-footer,
    #ez-autoair-new-bottom .itn-footer,
    #ez-autoair .itn-footer {
        position: relative;
        bottom: auto;
        background-color: transparent;
    }

    #ez-autoair-new-top .ez-fare-card,
    #ez-autoair-new-bottom .ez-fare-card,
    #ez-autoair .ez-fare-card {
        width: 18.9%;
        margin-left: 1.7%;
        padding: 0;

    }

    .ezair .itn-layover {
        padding: 0 4.5%;
    }

    .ez-itin-data[data-stops="3"] .itn-layover {
        padding: 0 2.5%;
    }

    .ez-itin-data[data-stops="4"] .itn-layover,
    .ez-itin-data[data-stops="5"] .itn-layover,
    .ez-itin-data[data-stops="6"] .itn-layover {
        padding: 0 1%;
    }

    .ez-rslt-fltr-fieldset .form-field {
        padding: .35em 1em .35em 0;
    }

    .ez-rslt-fares-explained {
        padding-top: 8px;
    }

    .recommended-top .bump {
        margin-top: 8px;
    }

    .recommended-time span {
        display: inline;
    }

    .recommended-time br {
        display: none;
    }
}

@media (min-width: 768px) {

    .itn-seat-list-btn,
    .ez-select-seat-btn {
        display: block;
    }

    .md-absolute {
        position: absolute;
    }

    .md-align-right {
        text-align: right;
    }

    .md-align-left {
        text-align: left;
    }

    .md-radius-10-bottom {
        border-radius: 0 0 10px 10px;
    }

    .ez-iframed .md-radius-10,
    .ez-iframed .md-radius-10-bottom,
    .ez-iframed .article-container {
        border-radius: 0 !important;
    }

    .md-radius-10 {
        border-radius: 10px;
    }

    #ez-waiting.pc {
        max-width: none;
    }

    .ez-waiting-logo-container {
        width: 48%;
        height: 0;
        padding-bottom: 20%;
        position: relative;
    }

    .ez-waiting-logo {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .ez-waiting-logo::after {
        color: #FFF;
        z-index: -1;
        position: relative;
        font-style: 30px;
    }

    .product-PC .ez-waiting-logo::after {
        content: "Princess Ezair";
    }

    .product-PC.booking-PO .ez-waiting-logo-img {
        margin-bottom: 50px;
    }

    .ez-waiting-title {
        font-size: 2.461538461538462em;
        /*32px*/
    }

    .ez-logo {
        font-size: 1.875em !important;
    }

    .ez-logo-reg {
        font-size: 0.466666em;
    }

    .ez-waiting-tagline {
        font-size: 2.461538461538462em;
        /*32px*/
    }

    .pc .ez-waiting-tagline-solo {
        font-size: 1.538461538461538em;
        /*20px*/
    }

    .ez-waiting-benefits {
        padding-top: 80px;
    }

    .ez-waiting-benefits.ez-waiting-benefits-ir {
        height: 140px;
    }

    .ez-waiting-benefits-item {
        padding-left: 70px;
        background-size: auto !important;
    }

    .ez-waiting-benefits-ir .ez-waiting-benefits-item::after {
        background-size: 170px auto !important;
    }

    .ez-waiting-message {
        text-shadow: none;
    }

    .pc .ez-waiting-message {
        padding-top: 5%;
        text-shadow: none;
    }

    .ez-waiting-title {
        font-size: 2.307692307692308em;
        /*30px*/
    }

    .ez-logo {
        font-size: 2em !important;
    }

    .ez-logo-reg {
        font-size: 0.466666em;
    }

    .ez-waiting-tagline {
        font-size: 2em;
        /*26px*/
    }

    .ez-waiting-benefits {
        padding-left: 10%;
        padding-top: 60px;
    }

    .ez-waiting-benefits-item {
        font-size: 1em;
        padding-left: 60px;
        background-size: 45px auto !important;
    }

    .ez-waiting-progress {
        margin-top: 10%;
    }

    .pc .ez-waiting-progress {
        margin-top: 6%;
    }

    .ez-waiting-progress-line {
        height: 8px;
    }

    .ez-waiting-message {
        padding-top: 50px;
    }

    .ez-waiting-lightbox.mfp-bg.mfp-ready {
        background: #000000 !important;
        /* override all mobile bg colors */
        opacity: .6;
        filter: alpha(opacity=60);
    }

    .ez-waiting-lightbox .mfp-container {
        padding: 30px 15px;
        background: none;
    }

    #ez-waiting {
        background: url(../../images/airbook/waiting-dark-bg.jpg) no-repeat 0 0;
        border-radius: 4px;
        background-size: cover;
    }

    .product-PC.booking-PC #ez-waiting,
    .product-PC.booking-JP #ez-waiting,
    .product-PC.booking-PA #ez-waiting {
        background-image: url(../../images/airbook/waiting-clouds-bg.jpg);
    }

    .product-CCL #ez-waiting,
    .product-CL #ez-waiting {
        background: url(../../images/airbook/waiting-white-bg.jpg) no-repeat 0 0;
    }

    /* giving a div an aspect ratio  */
    #ez-waiting {
        position: relative;
        width: 100%;
    }

    #ez-waiting:before {
        content: "";
        display: block;
        padding-top: 66.25%;
        /* this is the ratio of the bg image 960x636 */
    }

    /* end aspect ratio rules */
    .ez-waiting-bg {
        max-width: 100%;
        border-radius: 6px;
    }

    .ez-waiting-main {
        position: absolute;
        top: 0;
        overflow: hidden;
        padding-top: 5%;
    }

    .ez-waiting-main.ez-waiting-with-logo {
        padding-top: 0;
    }

    .ez-waiting-title {
        font-size: 2em;
        /*26px*/
    }

    .ez-waiting-logo-text {
        padding-top: 29px;
        display: inline-block;
        letter-spacing: 4px !important;
    }

    .ez-waiting-tagline {
        letter-spacing: 4px !important;
        font-size: 1.538461538461538em;
        /*20px*/
    }

    .ez-waiting-tagline-line1 {
        display: inline;
    }

    .ez-logo {
        border: 0 !important;
        font-size: 2.153845em !important;
    }

    .ez-logo-reg {
        font-size: 0.5em;
    }

    .ez-dash-line {
        width: 40px;
        /*width: 50px;*/
        height: 2px;
        top: 46%;
    }

    .ez-dash-line-left {
        left: -55px;
        /*left: -65px;*/
    }

    .ez-waiting-tagline-long .ez-dash-line-left {
        left: -37px;
    }

    .ez-dash-line-right {
        right: -55px;
        /*right: -65px;*/
    }

    .ez-waiting-tagline-long .ez-dash-line-right {
        right: -35px;
    }

    .ez-waiting-benefits {
        display: block;
        padding-left: 9%;
        font-size: 1.538461538461538em;
        /*20px*/
    }

    .ez-waiting-benefits-item {
        min-height: 115px;
        padding-top: 5px;
        font-size: 0.8em;
    }

    .ez-waiting-benefits-arrival::after {
        background: url(../../images/airbook/icon-lock.svg) no-repeat top left;
    }

    .no-svg .ez-waiting-benefits-arrival::after {
        background: url(../../images/airbook/icon-lock.png) no-repeat top left;
    }

    .ez-waiting-benefits-contact::after {
        background: url(../../images/airbook/icon-phone.svg) no-repeat top left;
    }

    .no-svg .ez-waiting-benefits-contact::after {
        background: url(../../images/airbook/icon-phone.png) no-repeat top left;
    }

    .ez-waiting-benefits-price::after {
        background: url(../../images/airbook/icon-pricetag.svg) no-repeat top left;
    }

    .no-svg .ez-waiting-benefits-price::after {
        background: url(../../images/airbook/icon-pricetag.png) no-repeat top left;
    }

    .product-HA .ez-waiting-benefits-arrival,
    .product-SB .ez-waiting-benefits-arrival,
    .product-CU .ez-waiting-benefits-arrival,
    .product-PC .ez-waiting-benefits-arrival {
        background: url(../../images/airbook/icon-lock.svg) no-repeat top left;
    }

    .product-HA .ez-waiting-benefits-contact,
    .product-SB .ez-waiting-benefits-contact,
    .product-CU .ez-waiting-benefits-contact,
    .product-PC .ez-waiting-benefits-contact {
        background: url(../../images/airbook/icon-phone.svg) no-repeat top left;
    }

    .product-HA .ez-waiting-benefits-price,
    .product-SB .ez-waiting-benefits-price,
    .product-CU .ez-waiting-benefits-price,
    .product-PC .ez-waiting-benefits-price {
        background: url(../../images/airbook/icon-pricetag.png) no-repeat top left;
    }

    .ez-waiting-progress {
        margin-top: 40px;
        height: 10px;
        position: relative;
        display: inline-block;
        /*max-width: 85%;*/
        width: 45%;
        background: none;
        height: auto;
        margin-bottom: auto;
    }

    .ez-waiting-progress-line {
        display: block;
        width: 100%;
        height: 6px;
        background: #333333;
        border-radius: 10px;
        box-shadow: 0px 2px 3px rgb(71, 111, 145);
        overflow: hidden;
    }

    .product-CCL .ez-waiting-progress-line,
    .product-CL .ez-waiting-progress-line {
        background: #b9c2ca;
    }

    .borderradius .product-CCL .ez-waiting-progress-line,
    .borderradius .product-CL .ez-waiting-progress-line {
        border: 1px solid #436D93;
    }

    .ez-waiting-progress-green {
        background: #0054a0;
        width: 0;
        height: 100%;
    }

    .product-CCL .ez-waiting-progress-green,
    .product-CL .ez-waiting-progress-green {
        background: #00ff26;
    }

    .ez-waiting-plane {
        display: block;
        position: absolute;
        left: -3%;
        width: 21%;
        margin-top: -9.9%
    }

    .ez-waiting-message {
        padding-top: 45px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
    }

    .pc .ez-waiting-msg-text {
        color: #333333;
    }

    .product-CCL .ez-waiting-title,
    .product-CL .ez-waiting-title {
        padding-left: 20px;
    }

    .product-CCL .ez-waiting-logo-img,
    .product-CL .ez-waiting-logo-img {
        width: 176px;
        height: 54px;
        background-size: auto;
        margin-top: 10px;
    }

    .product-CCL .ez-waiting-msg-text,
    .product-CL .ez-waiting-msg-text {
        color: white;
    }

    .product-CCL .ez-waiting-logo-text,
    .product-CL .ez-waiting-logo-text {
        padding-top: 22px;
        letter-spacing: 4px !important;
    }

    .auto-add-air-container {
        border-radius: 10px;
    }

    .recommended-stops {
        display: block;
    }

    .margin-15-right {
        margin-right: 15px;
    }

    .margin-15-left {
        margin-left: 15px;
    }

    .basic-economy-popup-btn {
        width: auto;
        border-radius: 10px;
        padding: 0px 2px 0px 2px;
        background-color: #ffcc00;
        text-align: center;
        color: black;
    }

    .learn-more-btn {
        padding-right: 25%;
    }
}

@media (max-width:47.9375em) {
    .headerbar-menu {
        margin-right: 0;
    }

    .headerbar-touch-btn .pc-icons,
    .menu-icon {
        display: block;
        margin: 0 auto;
    }

    .headerbar-btn.cruise-personalizer {
        display: inline-block;
    }

    .switch-guests .headerbar-touch-btn,
    .cruise-personalizer .headerbar-touch-btn,
    .call .headerbar-touch-btn {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pc-icons.view-cruise svg,
    .switch-guests .pc-icons.white-switch {
        top: 0;
    }

    .headerbar-btn.call .headerbar-btn-icon,
    .pc-icons.view-cruise,
    .switch-guests .headerbar-touch-btn .pc-icons {
        height: 20px;
        width: 20px;
        margin: 5px auto 0 !important;
    }

    .headerbar-btn-link:hover,
    .headerbar-btn-link:focus {
        background: inherit;
    }

    .svg-icon.icon-360-view {
        height: 17px;
    }

    header.pinned {
        min-height: 47px !important;
    }

    .switch-pnr-header {
        font-size: 21px !important;
    }

    .switch-pnr-guest {
        font-size: 16px !important;
    }

    #ez-search-sale-banner {
        width: auto;
        margin: 15px -15px -15px;
    }

    #ez-search-sale-banner.ez-search-sale-banner-pb {
        width: auto;
        margin: 0;
        margin-left: 15px;
        margin-right: 15px;
    }

    .guest-options-thead,
    .guest-options-row,
    .guest-options-cell {
        display: block;
    }

    .guest-options-row:not(.thead) .guest-options-cell {
        min-height: 0;
    }

    .cell-request-seats {
        display: none;
    }

    .hide-on-mobile {
        display: none;
    }

    .guest-options-row {
        padding: 10px 0;
    }

    .guest-options-modify .temp-form.show-selected~.open-temp-form {
        width: 70px;
    }

    .guest-options-modify .temp-form:not(.show-selected)~button.open-temp-form {
        display: none;
    }

    .guest-options-modify .show-selected.allSet~.open-temp-form .plus-button {
        display: none;
    }

    .guest-options-modify .show-selected~.open-temp-form .plus-button {
        display: block;
        left: auto;
        right: 10px;
    }

    .guest-options-modify .show-selected~.open-temp-form .edit-button {
        display: block;
        right: 60px;
    }

    .guest-options-modify .show-selected~.open-temp-form.text-button {
        display: none;
    }

    .guest-options-modify .known-trav .show-selected~.open-temp-form {
        right: 0;
    }

    .guest-options-modify .open-temp-form.text-button {
        display: block;
        margin-left: 15px;
        position: static;
        line-height: 1.2em;
        height: auto;
        width: 100% !important;
    }

    .guest-options-modify .square {
        display: none;
        position: relative;
    }

    .guest-options-modify .square.show-selected {
        display: block;
        padding: 10px 0;
        width: 70%;
    }

    .guest-options-modify .square.show-selected label.mobile-header {
        display: none;
    }

    .guest-options-modify .square.show-selected label.mobile-header-swap {
        display: block;
    }

    .guest-options-modify .square.show-selected .show-input,
    .guest-options-modify .temp-form.show-selected {
        text-align: left;
    }

    .guest-options-modify .square.show-selected .show-input,
    .guest-options-modify .square.show-selected .itn-carrier-logo {
        margin-left: 20px;
        padding: 0;
        margin-top: 10px;
    }

    .guest-options-modify .square.show-selected label:not(.gotham-bold) {
        margin-left: 20px;
        padding: 0;
        margin-top: 5px;
    }

    .guest-options-modify .square.show-selected .itn-carrier-logo+.show-input {
        margin-left: 5px;
        margin-top: 10px !important;
        height: 20px;
        line-height: 20px;
    }

    .guest-options-cell {
        line-height: 1.2em;
        text-align: left;
        padding: 0 10px;
    }

    .thin .content,
    .thin .guest-options-validate-buttons {
        width: 90%;
    }

    .guest-options-modify .square.show-selected~.square.show-selected label,
    .guest-options-modify .square.show-selected~.square.show-selected label {
        display: none;
    }

    .guest-options-modify .freq-flyer .square.show-selected~.square.show-selected,
    .guest-options-modify .special-services .square.show-selected~.square.show-selected,
    .guest-options-modify .known-trav .square.show-selected~.square.show-selected {
        margin-top: -15px;
    }

    #modify-content .light-blue-bg {
        background: #FFF;
    }

    #modify-content>.light-border {
        border: none;
    }

    #additional-guests {
        margin-top: 0;
    }

    .headerbar-touch-btn .pc-icons,
    .menu-icon {
        margin-bottom: 0;
    }

    .switch-guests .headerbar-touch-btn .pc-icons {
        margin-bottom: 2px;
    }

    .call .headerbar-touch-btn .pc-icons {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .headerbar-menu .switch-guests-dropdown {
        position: fixed;
        right: 0;
        left: 0;
        font-size: 1.7em;
        padding: 10px 20px 20px;
    }

    .switch-guests:hover .switch-guests-dropdown,
    .switch-guests.expanded>.switch-guests-dropdown {
        top: 47px;
    }

    .cell-buttons {
        display: block;
        margin: 0 15px;
    }

    .cell-buttons div {
        flex: none;
        margin: 0 0 10px;
    }

    .cell-buttons div:last-child {
        margin: 0;
    }

    .booked-flight-meta-details {
        margin-top: 5px;
    }

    .ez-fare-card-solo .sale-rbn {
        position: absolute;
        right: 0;
    }

    .ez-fare-card-solo .ez-fare-info {
        width: 48%;
        display: inline-block;
        padding-top: 24px;
        padding-bottom: 0;
        padding-right: 0;
    }

    .ez-fare-card-solo .ez-fare-select-btn {
        width: 50%;
        position: static;
        display: inline-block;
        vertical-align: bottom;
    }

    .ez-fare-card-solo .button {
        width: 80%;
        margin-left: 10%;
        position: static;
        padding: 5px;
    }

    .ez-card-cell {
        /*min-height: 0;*/
        padding-bottom: 15px;
    }

    .ui-datepicker {
        position: static;
    }

    .booked-flight-header-details {
        position: static;
        width: auto;
    }

    .booked-flight-header-details-content {
        height: auto !important;
    }

    .show-on-mobile {
        display: inline-block;
    }

    #popupContent input[type="checkbox"].select-all-passengers,
    #popupContent input[type="checkbox"].cancel-passenger {
        float: left;
    }

    #popupContent .guest-options-cell .mobile-label {
        margin-left: 20px;
    }

    #popupContent .e-ticket-model .guest-options-cell .mobile-label {
        margin-left: 0;
    }

    #popupContent .guest-options-cell .guest-name {
        display: inline-block;
        margin-left: 0px;
    }

    #popupContent .review-cancel-flights,
    #popupContent .confirm-cancel-flights {
        margin-top: 10px;
    }

    #popupContent .cancel-flights-2 .loading {
        margin: 20px 0 0;
        text-align: center;
        display: block;
    }

    #popupContent .ticket-block {
        margin-left: 18px;
    }

    .guest-options-cell-item {
        position: static;
        margin-left: 16px;
    }

    .guest-options-row .guest-options-cell:first-child {
        display: inline-flex;
    }

    #name-change-form-steps .name-opt-list {
        max-width: 86%;
    }

    .mn-input-wrapper {
        min-width: 65px;
        max-width: 105px;
    }
}

@media print {

    .ezair-manage-flights .article-header,
    #ez-manage-options,
    button,
    a,
    #ezair-header,
    #steps-bar {
        display: none !important;
    }

    body.ezair-manage-flights {
        /*font-size: 60%;*/
    }

    #ez-itn-details {
        /*max-width: 400px;*/
    }

    #content,
    #ez-manage-summary {
        padding-top: 0;
        margin-top: 0;
    }

    .itn-dtl-header,
    .itn-dtl-title {
        background-color: transparent !important;
        color: black !important;
        padding-left: .5em !important;
    }

    .itn-dtl-layover {
        color: black !important;
        border-top: 1px solid #d0d1d2;
        border-bottom: 1px solid #d0d1d2;
    }

    .guest-options-modify .temp-form.show-selected~.open-temp-form.text-button {
        display: none;
    }
}

@media (max-width: 639px) {
    .ez-from-price-header {
        display: none;
    }
}


@media (max-width: 932px) {
    .ez-rslt-main-modify .ez-fare-card {
        left: 25%;
    }

    #current-flight-itin {
        width: 780px
    }

    #fare-cards-header {
        width: 780px;
    }

    .fare-cards {
        width: 780px
    }

    #change-flight {
        margin-left: 0%;
    }

    #ez-dpt-summary .ez-dpt-summary-cell:first-child {
        width: 18%;
    }

}

@media (max-width: 768px) {
    .scroll-arrow {
        display: none;
    }

    .fare-cards-row {
        white-space: normal;
        margin-right: 0px;
        margin-left: 0px;
        overflow: initial;
        height: auto;

    }

    .economy-fare-card {
        display: block;
        margin-right: 0;
        height: 430px;
        width: 290px;
    }

    .current-flight-itin {
        display: none;
    }

    .fare-cards {
        display: grid;
    }

    #container-fareCards .fare-cards-container {
        padding-top: 0px;
        padding-left: 15px;

    }

    #fare-cards-container {
        margin-left: 0px;
        overflow-y: auto;
    }

    #fare-cards-header {
        display: none;
    }

    #fare-cards-header-mobile {
        display: flex;
        background-color: #FFF;
        width: 355px;
        height: 250px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    #fare-cards {
        width: 355px;
        height: fit-content;
    }

    #fare-header-container .itn-stop-container .row {
        min-height: fit-content;
    }

    #fare-cards-header-mobile .fare-header-heading {
        padding-top: 10px;
    }

    #container-fareCards .change-flight {
        font-size: 15px;
        padding-left: 0px;
    }

    #container-fareCards .itn-oper-details {
        margin-top: 110px;
    }

    #fare-cards-header-mobile .itn-total-duration {
        position: initial;
    }


    .recommended-flight-cost .recommended-cost {
        display: inline-flex;
    }

    .recommended-bottom {
        background-color: white;
    }

    .fare-button {
        line-height: 15px;
    }

    .recommended-bottom .itn-solo-farecard .itn-full-farecard-wrapper .ez-fare-card {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .basic-economy-popup-btn {
        width: auto;
        border-radius: 10px;
        background-color: #ffcc00;
        padding: 0;
        text-align: center;
    }

    .recommended-bottom .ez-itn-full-wrapper {
        position: static;
    }

    .mfp-close-wrapper {
        width: auto;
    }

    .recommended-flight-name {
        width: 75%;
    }

    .recommended-first-flight {
        display: none;
    }

    .recommended-second-flight {
        display: none;
    }

    .recommended-flight-cost {
        width: 25%;
    }

    #ez-flight-filter .ez-flight-filter-header {
        padding-left: 16px;
    }

    #ez-dpt-summary .ez-dpt-summary-cell:first-child {
        width: auto;
    }

    .itn-route {
        width: 100%;
    }

    .option-one .itn-footer {
        position: relative;
        bottom: auto;
    }

    .option-two .itn-footer {
        position: relative;
        bottom: auto;
    }

    .option-three .itn-footer {
        position: relative;
        bottom: auto;
    }

    .option-two .recommended-top {
        display: inline-block;
        width: 100%;
    }

    .option-three .recommended-top {
        display: inline-block;
        width: 100%;
    }

    .itn-full-farecard-wrapper {
        width: 100%;
        background: #fff;
    }

    .recommended-flight .ez-fare-card-flex {
        padding-bottom: 5%;
        padding-top: 5%;
    }

    .recommended-flight .ez-fare-card-restricted {
        padding-bottom: 5%;
        padding-top: 5%;
    }
}

@media (max-width: 1024px) {
    .option-one {
        display: grid;
    }

    .option-two {
        display: grid;
    }

    .option-three {
        display: grid;
    }
}

@media screen and (max-width: 480px) {
    .mfp-container {
        padding: 0;
    }

    .ez-iframed .mfp-wrap {
        overflow-y: auto;
    }
}